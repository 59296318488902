import IconHelper from "./IconHelper";

const QuestionMarkIcon = (props) => (
  <IconHelper {...props} defaultWidth={36} defaultHeight={36}>
    <g transform="translate(-29.238 -170.53)" strokeWidth="0">
      <circle cx="47.238" cy="188.53" r="18" fill="#fff" />
      <path
        transform="matrix(.26458 0 0 .26458 30.238 171.53)"
        d="m64.252 0a64.252 64.252 0 0 0-64.252 64.252 64.252 64.252 0 0 0 64.252 64.252 64.252 64.252 0 0 0 64.252-64.252 64.252 64.252 0 0 0-64.252-64.252zm0.1875 13.994c3.4153 0 6.6298 0.45086 9.6406 1.3496s5.6379 2.2455 7.8848 4.043c2.2918 1.7526 4.0894 3.9561 5.3926 6.6074 1.3032 2.6513 1.9551 5.7292 1.9551 9.2344 0 3.3254-0.58357 6.2901-1.752 8.8965s-2.6517 5.0109-4.4492 7.2129c-1.7526 2.157-3.6614 4.179-5.7285 6.0664-2.0671 1.8874-3.9994 3.7747-5.7969 5.6621-1.7526 1.8424-3.2144 3.7747-4.3828 5.7969-1.1684 2.0222-1.752 4.2472-1.752 6.6738 0 2.0671 0.24787 3.9545 0.74219 5.6621 0.49432 1.6627 0.98811 3.0777 1.4824 4.2461h-10.854c-0.40444-1.1684-0.76356-2.6732-1.0781-4.5156-0.31456-1.8874-0.4707-3.7533-0.4707-5.5957 0-2.876 0.56015-5.4582 1.6836-7.75 1.1684-2.3368 2.6068-4.5169 4.3145-6.5391 1.7076-2.0222 3.5735-3.9544 5.5957-5.7969 2.0222-1.8874 3.8861-3.7747 5.5938-5.6621 1.7076-1.9323 3.1227-3.9329 4.2461-6 1.1684-2.1121 1.7539-4.4268 1.7539-6.9434 0-2.157-0.42743-4.0658-1.2812-5.7285-0.80888-1.6627-1.9331-3.0562-3.3711-4.1797-1.438-1.1234-3.0989-1.9549-4.9863-2.4941-1.8874-0.58419-3.8879-0.875-6-0.875-7.5945 0-14.178 2.9881-19.75 8.9648v-11.863c6.8755-4.314 13.997-6.4727 21.367-6.4727zm-1.5508 84.596c2.0222 0 3.7749 0.74166 5.2578 2.2246 1.4829 1.3931 2.2246 3.1457 2.2246 5.2578 0 2.0671-0.74166 3.8198-2.2246 5.2578-1.5279 1.438-3.2806 2.1562-5.2578 2.1562s-3.728-0.71825-5.2559-2.1562c-1.4829-1.438-2.2246-3.1907-2.2246-5.2578 0-2.1121 0.74166-3.8648 2.2246-5.2578 1.4829-1.4829 3.2337-2.2246 5.2559-2.2246z"
        fill="#ff226f"
      />
    </g>
  </IconHelper>
);

export default QuestionMarkIcon;
