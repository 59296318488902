/**
 * Calculates x and y dimensions based on given defult dimensions. If either desried width or desired
 * height are given, resizing will be done proportional.
 *
 * @param {number} defaultWidth
 * @param {number} defaultHeight
 * @param {number} destWidth
 * @param {number} destHeight
 * @return {width: number, height: number} Calculated dimensions as object.
 */
export const calculateProportions = (
  defaultWidth,
  defaultHeight,
  destWidth = null,
  destHeight = null,
) => {
  const width = destWidth
    ? destWidth
    : destHeight
      ? (destHeight * defaultWidth * 1.0) / defaultHeight
      : defaultWidth;
  const height = destHeight
    ? destHeight
    : destWidth
      ? (destWidth * defaultHeight * 1.0) / defaultWidth
      : defaultHeight;

  return { width, height };
};
