export const closeDropdownById = (dropdownName: string) => {
  const dropdownButton = document.getElementById(
    dropdownName,
  ) as HTMLDivElement;
  const svgChild = dropdownButton?.firstElementChild as HTMLOrSVGImageElement;
  if (svgChild) {
    const transformStyle = svgChild.style.transform;
    if (transformStyle !== "") dropdownButton.click();
  }
};
