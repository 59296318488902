import { gql } from "@apollo/client";

const GET_ALL_OFFER_CAMPAIGNS = gql`
  query productOfferCampaigns(
    $productId: ID!
    $startDate: BigInt
    $endDate: BigInt
  ) {
    productOfferCampaigns(
      productId: $productId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      name
      productId
      product {
        enabled
      }
      bid
      cmsId
      countryGroupBudgets
      capping {
        dailyBudget
        monthlyBudget
        lifetimeBudget
        dailyConversionCap
        monthlyConversionCap
        lifetimeConversionCap
        dailyInstallCap
        monthlyInstallCap
        lifetimeInstallCap
      }
      costModel
      enabled
      enabledFromAdvertiser
      enabledAt
      startDate
      endDate
      druidResults
      multiPayout
      offerSteps {
        actionId
        description
        isPayout
        payoutDistribution
        minimumLatency
      }
    }
  }
`;

export default GET_ALL_OFFER_CAMPAIGNS;
