import { colors } from "theme";

const styles = () => ({
  outerDiv: (props) => ({
    height: props.height,
    width: props.height / 3.647,
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    display: "inline-block",
  }),
  innerDivFirst: {
    width: 0,
    height: 0,
    borderStyle: "solid",
    borderColor: `transparent transparent transparent ${colors.gray[100]}`,
    position: "absolute",
    top: -1,
    left: 1.6,
  },
  innerDivSecond: {
    width: 0,
    height: 0,
    borderStyle: "solid",
    borderColor: "transparent transparent transparent white",
    position: "absolute",
    top: -1,
    left: 0,
  },
});

export default styles;
