import { gql } from "@apollo/client";

const POST_PRODUCT = gql`
  mutation productCreate($advertiserId: ID!, $bundleId: String!) {
    productCreate(input: { advertiserId: $advertiserId, bundleId: $bundleId }) {
      product {
        id
        name
        iconUrl
        advertiserId
      }
      errors
    }
  }
`;

export default POST_PRODUCT;
