// TODO merge getNumberValue and getCurrencyValue into one by using "formatter.formatToParts(...)",
// also considering the right place for abbreviation suffix for currencies if locale != "en-..", ...
// e.g. "$20.6k" vs. "20,6[ ]K €"

export const getNumberValue = (value, numberSetting, hint) => {
  if (isNaN(parseFloat(value)) || !isFinite(parseFloat(value))) {
    return value;
  }

  const config = numberSetting || {};

  let origin = new Intl.NumberFormat(
    config.locale !== undefined ? config.locale : "en-US",
    {
      minimumFractionDigits:
        config.decimalPlaces !== undefined ? config.decimalPlaces : 2,
      maximumFractionDigits:
        config.decimalPlaces !== undefined ? config.decimalPlaces : 2,
    },
  ).format(value);
  origin =
    (config.prefix ? config.prefix : "") +
    origin +
    (config.suffix ? config.suffix : "");

  const hintValue = numberSetting.unmodifiedValueForHint
    ? (config.prefix ? config.prefix : "") +
      value +
      (config.suffix ? config.suffix : "")
    : origin;

  if (config.abbreviate) {
    const { shortened, suffix: abbreviationSuffix } = abbreviateNumber(value);
    const shortNumber = new Intl.NumberFormat(
      config.locale !== undefined ? config.locale : "en-US",
      {
        minimumFractionDigits:
          config.abbreviatedDecimalPlaces !== undefined
            ? config.abbreviatedDecimalPlaces
            : config.decimalPlaces !== undefined
              ? config.decimalPlaces
              : 2,
        maximumFractionDigits:
          config.abbreviatedDecimalPlaces !== undefined
            ? config.abbreviatedDecimalPlaces
            : config.decimalPlaces !== undefined
              ? config.decimalPlaces
              : 2,
      },
    ).format(shortened);

    if (abbreviationSuffix !== "") {
      return (
        <span title={hint ? `${hint}: ${hintValue}` : hintValue}>
          {(config.prefix ? config.prefix : "") +
            shortNumber +
            abbreviationSuffix +
            (config.suffix ? config.suffix : "")}
        </span>
      );
    }
  }
  return (
    <span title={hint ? `${hint}: ${hintValue}` : hintValue}>{origin}</span>
  );
};

export const getCurrencyValue = (value, currencySetting, hint) => {
  const config = currencySetting || {};

  const origin = new Intl.NumberFormat(
    config.locale ? config.locale : "en-US",
    {
      style: "currency",
      currency: config.currencyCode ? config.currencyCode : "USD",
      minimumFractionDigits:
        config.decimalPlaces !== undefined ? config.decimalPlaces : 2,
      maximumFractionDigits:
        config.decimalPlaces !== undefined ? config.decimalPlaces : 2,
    },
  ).format(value !== undefined ? value : 0);

  if (config.abbreviate) {
    const { shortened, suffix } = abbreviateNumber(value);

    const shortNumber = new Intl.NumberFormat(
      config.locale ? config.locale : "en-US",
      {
        style: "currency",
        currency: config.currencyCode ? config.currencyCode : "USD",
        minimumFractionDigits:
          config.abbreviatedDecimalPlaces !== undefined
            ? config.abbreviatedDecimalPlaces
            : config.decimalPlaces !== undefined
              ? config.decimalPlaces
              : 2,
        maximumFractionDigits:
          config.abbreviatedDecimalPlaces !== undefined
            ? config.abbreviatedDecimalPlaces
            : config.decimalPlaces !== undefined
              ? config.decimalPlaces
              : 2,
      },
    ).format(shortened);

    if (suffix !== "") {
      return (
        <span title={hint ? `${hint}: ${origin}` : origin}>
          {(config.prefix ? config.prefix : "") +
            shortNumber +
            suffix +
            (config.suffix ? config.suffix : "")}
        </span>
      );
    }
  }
  return config.noTooltip ? (
    origin
  ) : (
    <span
      title={typeof hint === "string" && hint ? `${hint}: ${origin}` : origin}
    >
      {origin}
    </span>
  );
};

export const abbreviateNumber = (value) => {
  // TODO pass and consider locale, e.g. en -> 20k vs. de -> 20 K
  let newValue = parseFloat(value || 0);

  const suffixes = ["", "K", "M", "bn"]; //, "t"];
  let index = 0;

  while (newValue >= 1000 && index < suffixes.length) {
    newValue /= 1000;
    index++;
  }

  return { shortened: newValue, suffix: suffixes[index] };
};
