import { CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import AppBar from "components/AppBar/AppBar";
import useShowToast from "hooks/useShowToast";
import useViewByRole from "hooks/useViewByRole";
import { useEffect, useState } from "react";
import { useNotificationStore } from "store/notification";
import { USER_ROLES } from "utils/constants";
import FullscreenInfo from "../components/Modal/FullscreenInfo";
import SideMenu from "../components/SideMenu/SideMenu";
import styles from "./LayoutStyles";

const Layout = (props: any) => {
  const { classes, entries, children } = props;

  const [navMenuOpen, setNavMenuOpen] = useState<boolean>(false);
  const [subMenuOpened, setSubMenuOpened] = useState<boolean>(false);
  const [logoutInProgress, setLogoutInProgress] = useState<boolean>(false);
  const [filteredEntries, setFilteredEntries] = useState(entries);

  const { showError } = useShowToast();

  const userHasRole = useViewByRole();

  const popErrors = useNotificationStore((state) => state.popErrors);
  const errorMessages = useNotificationStore((state) => state.errorMessages);

  useEffect(() => {
    if (userHasRole(USER_ROLES.ADVERTISER_USER))
      setFilteredEntries((entries) =>
        entries.filter((entry) => entry.caption !== "Users"),
      );
  }, []);

  useEffect(() => {
    if (!errorMessages.length) return;
    const errors = popErrors();
    errors.forEach((error) => showError(error));
  }, [errorMessages]);

  return (
    <div className={classes.root}>
      <SideMenu
        entries={filteredEntries}
        navMenuOpen={navMenuOpen}
        setNavMenuOpen={setNavMenuOpen}
        subMenuOpened={subMenuOpened}
        setSubMenuOpened={setSubMenuOpened}
      />
      <div
        style={{ display: "flex", flexDirection: "column" }}
        className={clsx(classes.content, {
          [classes.contentCollapsed]: !navMenuOpen,
          [classes.contentExpanded]: navMenuOpen,
        })}
      >
        <AppBar
          position={"absolute"}
          navMenuOpen={navMenuOpen}
          logoutInProgress={() => setLogoutInProgress(true)}
          entries={filteredEntries}
        />
        <main>
          {children}
          {logoutInProgress && (
            <CircularProgress
              id="PROGRESS"
              size={40}
              className={classes.loadingIndicator}
            />
          )}
        </main>
        <FullscreenInfo />
      </div>
    </div>
  );
};

// @ts-expect-error
export default withStyles(styles, { withTheme: true })(Layout);
