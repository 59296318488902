import { colors } from "../../theme";
import IconHelper from "./IconHelper";

const ClearSearchIcon = () => {
  const iconSize = 14;
  return (
    <IconHelper
      width={iconSize}
      height={iconSize}
      defaultWidth={20}
      defaultHeight={20}
    >
      <path
        fill={colors.blueGrey}
        d="m10 0c-5.53 0-10 4.47-10 10s4.47 10 10 10 10-4.47 10-10-4.47-10-10-10zm5 13.59-1.41 1.41-3.59-3.59-3.59 3.59-1.41-1.41 3.59-3.59-3.59-3.59 1.41-1.41 3.59 3.59 3.59-3.59 1.41 1.41-3.59 3.59z"
      />
    </IconHelper>
  );
};

export default ClearSearchIcon;
