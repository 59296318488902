import { withStyles } from "@material-ui/core/styles";
import styles from "./BreadcrumbDividerStyles";

const BreadcrumbDivider = (props) => {
  const { height, classes } = props;
  const width = height / 3.647;
  const borderWidth = `${(height + 4) / 2}px 0 ${(height + 4) / 2}px ${
    width - 2
  }px`;

  return (
    <div className={classes.outerDiv} style={{ minWidth: width }}>
      <div
        className={classes.innerDivFirst}
        style={{ borderWidth: borderWidth }}
      ></div>
      <div
        className={classes.innerDivSecond}
        style={{ borderWidth: borderWidth }}
      ></div>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(BreadcrumbDivider);
