import IconHelper from "./IconHelper";

const FinanceIcon = (props) => (
  <IconHelper {...props} defaultWidth={24} defaultHeight={23.9}>
    <path
      d="m16.3 14.6c0 0.5 0.3 0.9 0.6 0.9h0.5c0.3 0 0.6-0.3 0.6-0.9 0-0.5-0.3-0.9-0.6-0.9h-0.5c-0.4 0-0.6 0.4-0.6 0.9m6.7 2.5h-6.2c-1.5 0-2.8-1.3-2.8-2.8s1.3-2.8 2.8-2.8h6.2zm-1.1 5.2c0 0.4-0.3 0.8-0.7 0.8h-19.5c-0.4-0.1-0.7-0.4-0.7-0.8v-16c0-0.4 0.3-0.8 0.7-0.8h19.5c0.4 0 0.7 0.4 0.7 0.8v4.1h-5.1c-2.1 0-3.8 1.7-3.8 3.8s1.7 3.8 3.8 3.8h5.1zm-2.7-21.3c0.3 0 0.5 0.4 0.5 0.8v2.8h-14zm4.3 9.4h-0.6v-4.1c0-1-0.8-1.7-1.7-1.7h-0.5v-2.9c0-1-0.7-1.7-1.6-1.7h-0.1l-17.4 4.5c-0.9 0.1-1.6 0.8-1.6 1.7v16c0 1 0.8 1.7 1.7 1.7h19.5c1 0 1.7-0.8 1.7-1.7v-4.2h0.6c0.3 0 0.5-0.2 0.5-0.5v-6.6c0-0.3-0.3-0.5-0.5-0.5z"
      clipRule="evenodd"
      fill="#fff"
      fillRule="evenodd"
    />
  </IconHelper>
);

export default FinanceIcon;
