import { CircularProgress, withStyles } from "@material-ui/core";
import { colors } from "theme";

const styles = () => ({
  loadingIndicator: (props) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: Number.isInteger(props.size) ? parseInt(props.size) / -2 : -20,
    marginLeft: Number.isInteger(props.size) ? parseInt(props.size) / -2 : -20,
    color: props.circleColor || colors.red[500],
  }),
});

const LoadingIndicator = (props) => {
  const { classes, theme, size, circleColor, ...rest } = props;
  return (
    <CircularProgress
      className={classes.loadingIndicator}
      {...rest}
      size={size || 60}
    />
  );
};

export default withStyles(styles, { withTheme: true })(LoadingIndicator);
