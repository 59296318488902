import { gql } from "@apollo/client";

const PUT_OFFER_CAMPAIGN = gql`
  mutation offerCampaignUpdate(
    $id: ID!
    $igniteCampaignId: ID
    $icon: IconInput
    $enabled: Boolean
    $name: String
    $comment: String
    $attributionProvider: AttributionProviderEnum
    $actionType: ActionTypeEnum
    $title: String
    $adContent: AdContentEnum
    $clickUrl: String
    $complexity: Int
    $bid: Float
    $startDate: BigInt
    $endDate: BigInt
    $dynamicPayouts: Boolean
    $capping: CappingInput
    $minimumLatency: Int
    $attributionWindow: Int
    $instructionSteps: [String!]
    $offerSteps: [OfferStepCreateInput!]
    $deviceTargeting: DeviceTargetingInput
    $locationTargeting: LocationTargetingInput
    $applicationTargeting: ApplicationTargetingInput
    $offerTranslationsAttributes: [OfferTranslationUpdateInput!]
    $creatives: [OfferCampaignCreativeCreateInput!]
  ) {
    offerCampaignUpdate(
      input: {
        id: $id
        attributes: {
          enabled: $enabled
          icon: $icon
          name: $name
          comment: $comment
          attributionProvider: $attributionProvider
          actionType: $actionType
          bid: $bid
          title: $title
          adContent: $adContent
          igniteCampaignId: $igniteCampaignId
          clickUrl: $clickUrl
          complexity: $complexity
          startDate: $startDate
          endDate: $endDate
          minimumLatency: $minimumLatency
          attributionWindow: $attributionWindow
          instructionSteps: $instructionSteps
          offerSteps: $offerSteps
          dynamicPayouts: $dynamicPayouts
          capping: $capping
          deviceTargeting: $deviceTargeting
          locationTargeting: $locationTargeting
          applicationTargeting: $applicationTargeting
          offerTranslationsAttributes: $offerTranslationsAttributes
          creatives: $creatives
        }
      }
    ) {
      errors
      offerCampaign {
        id
        productId
        igniteCampaignId
        product {
          id
          name
          advertiser {
            id
            name
            cmsId
          }
          iconUrl
          platform
          samsungStore
          adContent
          appCategoryGenre
          secondaryCategories
          enabled
        }
        iconUrl
        name
        bid
        costModel
        actionType
        attributionProvider
        enabled
        enabledFromAdvertiser
        enabledAt
        updatedAt
        title
        comment
        adContent
        clickUrl
        complexity
        startDate
        endDate
        minimumLatency
        attributionWindow
        multiPayout
        instructionSteps
        offerSteps {
          description
          isPayout
          actionId
          payoutDistribution
          minimumLatency
        }
        countryGroupBudgets
        dynamicPayouts
        capping {
          dailyBudget
          monthlyBudget
          lifetimeBudget
          dailyConversionCap
          # monthlyConversionCap
          lifetimeConversionCap
          dailyInstallCap
          # monthlyInstallCap
          lifetimeInstallCap
        }
        deviceTargeting {
          iosOsVersions {
            firstVersion
            lastVersion
          }
          androidOsVersions {
            firstVersion
            lastVersion
          }
          phoneTargeted
          tabletTargeted
          deviceManufacturers {
            kind
            lists {
              manufacturer
              models
            }
          }
        }
        locationTargeting {
          countryCodes
          global
        }
        applicationTargeting {
          targetType
          apps {
            id
            name
            cmsId
            iconUrl
            platform
          }
        }
        offerTranslations {
          id
          localizedTitle
          localizedSteps
          localizedInstructionSteps
          localizedComment
          countryCodes
        }
        creatives {
          id
          name
          assets {
            id
            kind
            state
            name
            url {
              raw
              low
              medium
              high
            }
            aspectRatio
            duration
            height
            orientation
            size
            width
            createdAt
            updatedAt
            advertiser {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export default PUT_OFFER_CAMPAIGN;
