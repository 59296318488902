import IconHelper from "./IconHelper";

const ReportsIcon = (props) => (
  <IconHelper {...props} defaultWidth={22} defaultHeight={25}>
    <path
      d="m20.5 0h-5c-0.8 0-1.5 0.7-1.5 1.5v4.6c-0.2-0.1-0.3-0.1-0.5-0.1h-5c-0.8 0-1.5 0.7-1.5 1.5v5.6c-0.2-0.1-0.3-0.1-0.5-0.1h-5c-0.8 0-1.5 0.7-1.5 1.5v9c0 0.8 0.7 1.5 1.5 1.5h19c0.8 0 1.5-0.7 1.5-1.5v-22c0-0.8-0.7-1.5-1.5-1.5zm-12.5 14.5v-7c0-0.3 0.2-0.5 0.5-0.5h5c0.3 0 0.5 0.2 0.5 0.5v16c0 0.3-0.2 0.5-0.5 0.5h-5c-0.3 0-0.5-0.2-0.5-0.5zm-7 9v-9c0-0.3 0.2-0.5 0.5-0.5h5c0.3 0 0.5 0.2 0.5 0.5v9c0 0.3-0.2 0.5-0.5 0.5h-5c-0.3 0-0.5-0.2-0.5-0.5zm20 0c0 0.3-0.2 0.5-0.5 0.5h-5c-0.3 0-0.5-0.2-0.5-0.5v-22c0-0.3 0.2-0.5 0.5-0.5h5c0.3 0 0.5 0.2 0.5 0.5z"
      clipRule="evenodd"
      fill="#fff"
      fillRule="evenodd"
    />
  </IconHelper>
);

export default ReportsIcon;
