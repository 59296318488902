import { useUserStore } from "store/user";

export default function useViewByRole() {
  const user = useUserStore((state) => state.user);

  const userHasRole = (roles: string | string[]) => {
    const allowedRoles = [].concat(roles);
    return allowedRoles.some((role) => user?.roles.includes(role));
  };

  return userHasRole;
}
