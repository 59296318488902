import create from "zustand";

interface Store {
  errorMessages: string[];
  popErrors: () => string[];
  addErrorMessage: (message: string) => void;
}

export const useNotificationStore = create<Store>((set, get) => ({
  errorMessages: [],

  popErrors: () => {
    const errors = [...get().errorMessages];

    set(() => ({ errorMessages: [] }));

    return errors;
  },

  addErrorMessage: (message) =>
    set((prev) => {
      const errorMessages = [...prev.errorMessages, message];
      return { errorMessages };
    }),
}));
