import { colors } from "theme";

const styles = () => ({
  root: (props) => ({
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    height: props.height ? props.height : null,
    backgroundColor: colors.white,
    width: "100%",
    maxWidth: props.maxWidth ? props.maxWidth : null,
    borderBottom: props.bottomBorder ? `solid 1px ${colors.gray[100]}` : null,
  }),
  controlsWrapper: {
    marginLeft: "auto",
    marginRight: (props) => (props.height ? (24.0 * props.height) / 65 : 24),
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
});

export default styles;
