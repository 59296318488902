import { createStyles } from "@material-ui/core";
import { colors } from "theme";

const styles = createStyles({
  subListItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  subListItemParentSelected: {
    backgroundColor: "#00000024",
  },
  subListItemText: {
    fontSize: 13,
    fontWeight: 300,
    marginLeft: 30,
  },
  subListItemTextSelected: {
    fontSize: 13,
    fontWeight: 600,
    marginLeft: 30,
  },
  subListItemIcon: {
    paddingLeft: 5.5,
  },
  navlink: () => ({
    textDecoration: "none",
    outline: "none",
    "& > .Mui-selected": {
      cursor: "default",
    },
  }),
  navHidden: {
    visibility: "hidden",
  },
  navItemText: {
    color: colors.white,
    fontSize: 15,
  },
  navIcon: {
    minWidth: 32,
  },
  subMenuSelector: {
    background: colors.black,
    width: 1,
    height: 28,
    marginLeft: 20,
    position: "absolute",
  },
  subMenuSelectedIndicator: {
    background: colors.white,
  },
});

export default styles;
