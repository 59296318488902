import IconHelper from "./IconHelper";

const WebIcon = (props) => {
  const { outlined, ...otherProps } = props;

  const size = outlined ? 36 : 34;

  const path = (
    <path
      d="m13.463 18.202c0-0.70833 0.09971-1.3155 0.09971-2.0238h-3.39c-0.19941 0.60714-0.29912 1.3155-0.29912 2.0238 0 0.70834 0.09971 1.4167 0.29912 2.0238h3.39c0-0.70834-0.09971-1.3155-0.09971-2.0238zm-2.393 4.0476c0.99707 1.7202 2.4927 2.9345 4.2874 3.6429-0.59824-1.1131-1.0968-2.3274-1.3959-3.6429zm0-8.0952h2.8915c0.29912-1.2143 0.79765-2.5298 1.3959-3.6429-1.7947 0.70834-3.39 1.9226-4.2874 3.6429zm6.8798-4.0476c-0.79765 1.2143-1.4956 2.5298-1.8944 4.0476h3.7889c-0.39883-1.4167-1.0968-2.8333-1.8944-4.0476zm6.8798 4.0476c-0.99707-1.7202-2.4927-2.9345-4.2874-3.6429 0.59824 1.1131 1.0968 2.3274 1.3959 3.6429zm-4.2874 11.738c1.7947-0.60714 3.39-1.9226 4.2874-3.6429h-2.9912c-0.19941 1.2143-0.69795 2.5298-1.2962 3.6429zm-2.2933-24.893h-0.49853c-9.2727 0-16.751 7.5893-16.751 17 0 9.4107 7.478 17 16.751 17h0.49853c9.2727 0 16.751-7.5893 16.751-17 0-9.4107-7.478-17-16.751-17zm-0.29912 27.321c-2.6921 0-5.1848-1.1131-7.0792-2.9345-1.8944-1.8214-2.8915-4.4524-2.8915-7.1845 0-5.5655 4.4868-10.119 9.9707-10.119 2.6921 0 5.1848 1.1131 7.0792 2.9345 1.8944 1.8214 2.8915 4.4524 2.8915 7.1845 0 5.5655-4.4868 10.119-9.9707 10.119zm4.4868-10.119c0 0.70834-0.09971 1.3155-0.09971 2.0238h3.39c0.19941-0.60714 0.29912-1.3155 0.29912-2.0238 0-0.70833-0.09971-1.4167-0.29912-2.0238h-3.39c0 0.70833 0.09971 1.3155 0.09971 2.0238zm-4.4868 8.0952c0.79766-1.2143 1.4956-2.5298 1.8944-4.0476h-3.7889c0.39883 1.4167 1.0968 2.8333 1.8944 4.0476zm-2.2933-10.119c-0.09971 0.70833-0.19941 1.3155-0.19941 2.0238 0 0.70834 0.09971 1.3155 0.19941 2.0238h4.6862c0.09971-0.70834 0.19941-1.3155 0.19941-2.0238 0-0.70833-0.09971-1.3155-0.19941-2.0238z"
      fill="#4a90e2"
      strokeWidth="1.0045"
    />
  );

  const transform = outlined ? null : (
    <g transform="translate(-1 -1)">{path}</g>
  );

  const outline = outlined ? (
    <circle cx="18" cy="18" r="18" fill="#ffffff" strokeWidth="0" />
  ) : null;

  return (
    <IconHelper {...otherProps} defaultWidth={size} defaultHeight={size}>
      {outline}
      {outlined ? path : transform}
    </IconHelper>
  );
};

export default WebIcon;
