import { OpenInNewSharp } from "@material-ui/icons";
import { lazy } from "react";
import CreateOfferIcon from "../assets/icons/CreateOfferIcon";
import DashboardIcon from "../assets/icons/DashboardIcon";
import FinanceIcon from "../assets/icons/FinanceIcon";
import ReportsIcon from "../assets/icons/ReportsIcon";
import UserIcon from "../assets/icons/UserIcon";
import { EDGE_REPORTS_URL, OFFER_TYPE, PageIds } from "../utils/constants";
import { getOfferPath } from "../utils/helpers";

const Dashboard = lazy(
  () =>
    import(
      /* webpackChunkName: "Dashboard" */ "../containers/Dashboard/Dashboard"
    ),
);
const OfferDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "OfferDetails" */ "../containers/Dashboard/OfferDetails"
    ),
);
const ProductDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "ProductDetails" */ "../containers/Dashboard/ProductDetails"
    ),
);
const Offer = lazy(
  () => import(/* webpackChunkName: "Offer" */ "../containers/Offer/Offer"),
);
const Account = lazy(
  () =>
    import(/* webpackChunkName: "Account" */ "../containers/Account/Account"),
);
const UserManagement = lazy(
  () =>
    import(
      /* webpackChunkName: "UserManagement" */ "../containers/Management/Users/UserManagement"
    ),
);

const TopOffers = lazy(
  () => import("../containers/Account/TodaysBestOffer/TodaysBestContainer"),
);

const iconHeight = 20;

export const PAGE_PATHS = {
  // w/o authentication
  login: "/login",
  password: "/password",
  register: "/register",

  // secured
  createOffer: `/offer/:productId?`,
  editOffer: "/editOffer/:offerId",
  dashboard: "/",
  offerDetails: "/offerDetails/:offerId",
  productDetails: "/productDetails/:productId",
  account: "/account",
  support: "/help",
  managementUsers: "/users",
  topOffers: "/account/todays-best",
};

export const routes = [
  {
    path: PAGE_PATHS.createOffer,
    getNavLink: () => getOfferPath(OFFER_TYPE.OFW),
    exact: false,
    component: Offer,
    icon: <CreateOfferIcon height={iconHeight} />,
    caption: "Add Campaign",
    id: PageIds.CREATE_OFFER,
  },
  {
    path: PAGE_PATHS.editOffer,
    exact: false,
    component: Offer,
    id: PageIds.EDIT_OFFER,
    getMenuItemPageId: () => PageIds.DASHBOARD, // indicate which entry in navigation should be highlighted
  },
  {
    path: PAGE_PATHS.dashboard,
    exact: true,
    component: Dashboard,
    icon: <DashboardIcon height={iconHeight} />,
    caption: "Dashboard",
    id: PageIds.DASHBOARD,
  },
  {
    path: PAGE_PATHS.offerDetails,
    exact: false,
    component: OfferDetails,
    id: PageIds.OFFER_DETAILS,
    getMenuItemPageId: () => PageIds.DASHBOARD, // indicate which entry in navigation should be highlighted
  },
  {
    path: PAGE_PATHS.productDetails,
    exact: false,
    component: ProductDetails,
    id: PageIds.PRODUCT_DETAILS,
    getMenuItemPageId: () => PageIds.DASHBOARD, // indicate which entry in navigation should be highlighted
  },
  {
    // TODO for version > v1 -> remove
    path: EDGE_REPORTS_URL,
    external: true,
    // TODO for version > v1 -> enable and handle
    // path: "/reporting",
    component: null,
    icon: <ReportsIcon height={iconHeight} />,
    caption: (
      <>
        Reporting
        <OpenInNewSharp
          style={{ fontSize: 16, marginLeft: 5, top: 3, position: "relative" }}
        ></OpenInNewSharp>
      </>
    ),
    id: PageIds.REPORTING,
  },
  {
    path: PAGE_PATHS.account,
    component: Account,
    icon: <FinanceIcon height={iconHeight} />,
    caption: "Account",
    id: PageIds.ACCOUNT,
    isProfile: true,
  },
  {
    path: PAGE_PATHS.managementUsers,
    component: UserManagement,
    icon: <UserIcon height={iconHeight} />,
    caption: "Users",
    id: PageIds.MANAGEMENT_USERS,
  },
  {
    path: PAGE_PATHS.TopOffers,
    component: TopOffers,
    id: PageIds.OFFER_OF_THE_DAY,
  },
];
