import { gql } from "@apollo/client";

const GET_ALL_ADVERTISERS = gql`
  query advertisers {
    advertisers {
      id
      cmsId
      name
      offerTypes
      trusted
      direct
      totalBalance
      unlimitedBalance
      apiTokens {
        kind
        readValue
      }
      advertiserTier {
        id
        name
      }
    }
  }
`;

export default GET_ALL_ADVERTISERS;
