import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Children, cloneElement } from "react";
import { calculateProportions } from "../../utils/calculations";

const styles = {
  root: (props) => {
    const { width, height } = calculateProportions(
      props.defaultWidth,
      props.defaultHeight,
      props.width,
      props.height,
    );
    return {
      width: width,
      height: height,
    };
  },
};

// TODO support also stroke (color)

/**
 * Use this function as a wrapper for SVG icons.
 * Properties supported:
 * - defaultWidth, defaultHeight - dimensions as used in viewBox of the SVG (there should be no width/height applied
 *           to your SVG - values identical with viewBox and "100%" can be ignored)
 * - width and/or height - in pixels, if only one is given the icon will be sized proportinal
 * - children - just the SVG nodes which are inside between the <svg> tags
 * - color - color code as string, will be used to replace children's "fill" attribute. if your SVG elements contain
 *           "fill" properties which should NOT be affected, add a property "replacefill=[any string]" to the elements
 *           whose "fill" property value SHOULD BE replaced (see AndroidIcon, outlined variant)
 *
 * @param {*} props
 */
const IconHelper = (props) => {
  const {
    height,
    width,
    defaultWidth,
    defaultHeight,
    color,
    children,
    classes,
    className,
    ...rest
  } = props;

  const viewBox = `0 0 ${defaultWidth} ${defaultHeight}`;

  const hasReplaceFill = (children) => {
    const element = Children.toArray(children).find((childNode) => {
      if (childNode.props.replacefill) {
        return true;
      }
      return childNode.props.children
        ? hasReplaceFill(childNode.props.children)
        : false;
    });
    return element ? true : false;
  };

  const replaceColor = (children, colorProperty) => {
    return Children.map(children, (childNode) => {
      return childNode.props && childNode.props[colorProperty]
        ? cloneElement(
            childNode,
            { replacefill: null, fill: color },
            replaceColor(childNode.props.children, colorProperty),
          )
        : cloneElement(
            childNode,
            [],
            replaceColor(childNode.props.children, colorProperty),
          );
    });
  };

  const changeColor = (children) => {
    const hasReplace = hasReplaceFill(children);
    return replaceColor(children, hasReplace ? "replacefill" : "fill");
  };

  return (
    <SvgIcon
      {...rest}
      className={[classes.root, className].join(" ")}
      viewBox={viewBox}
    >
      {color ? changeColor(children) : children}
    </SvgIcon>
  );
};

IconHelper.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default withStyles(styles)(IconHelper);
