import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useSnackbar } from "notistack";
import { handleToastMessage } from "../utils/helpers";

/**
 * Enables closing of toast messages (before hide timeout reached).
 */
const getCloseSnackbar = (closeSnackbar) => {
  return (key) => (
    <IconButton
      key="close"
      aria-label="Close"
      color="inherit"
      onClick={(e) => {
        e.preventDefault();
        closeSnackbar(key);
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};

type ToastKind = "info" | "success" | "warning" | "error";
type ToastFn = (message: any) => void;

export default function useShowToast() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const closeSnackbarAction = getCloseSnackbar(closeSnackbar);

  function showToast(kind: ToastKind, message: string | string[]) {
    enqueueSnackbar(handleToastMessage(message), {
      action: closeSnackbarAction,
      variant: kind,
      ContentProps: {
        classes: { root: `notisnack-${kind}` },
      },
    });
  }

  const showInfo: ToastFn = (message: string | string[]) =>
    showToast("info", message);
  const showSuccess: ToastFn = (message: string | string[]) =>
    showToast("success", message);
  const showWarning: ToastFn = (message: string | string[]) =>
    showToast("warning", message);
  const showError: ToastFn = (message: string | string[]) =>
    showToast("error", message);
  return { showInfo, showSuccess, showWarning, showError };
}
