import { NAV_MENU_WIDTH } from "../utils/constants";

const styles = (theme) => ({
  root: {
    display: "flex",
    height: "100vh",
  },
  content: {
    overflowY: "auto",
    overflowX: "hidden",
    width: "100%",
    marginLeft: 0,
    display: "flex",
    flexDirection: "column",
  },
  contentCollapsed: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentExpanded: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: NAV_MENU_WIDTH.open - NAV_MENU_WIDTH.closed,
  },
  loadingIndicator: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -20,
    marginLeft: -20,
  },
});

export default styles;
