import IconHelper from "./IconHelper";

const IosIcon = (props) => {
  const { outlined, ...otherProps } = props;
  return outlined ? (
    <IconHelper {...otherProps} defaultWidth={36} defaultHeight={36}>
      <rect
        x="5e-7"
        y="5e-7"
        width="36"
        height="36"
        ry="6.874"
        fill="#fff"
        strokeWidth="0"
      />
      <path
        d="m29.135 1h-22.269c-3.1813 0-5.8655 2.6231-5.8655 5.9525v22.095c0 3.2285 2.5848 5.9525 5.8655 5.9525h22.269c3.1813 0 5.8655-2.6231 5.8655-5.9525v-22.095c0-3.3294-2.6842-5.9525-5.8655-5.9525zm-10.041 5.7507c0.79532-1.0089 2.0877-1.7151 3.2807-1.7151 0.09942 1.3116-0.39766 2.724-1.193 3.7329-0.79532 1.0089-1.9883 1.7151-3.2807 1.6142-0.19883-1.3116 0.49708-2.724 1.193-3.632zm6.2632 18.564c-0.89474 1.4125-1.8889 2.8249-3.3801 2.8249s-1.9883-0.90801-3.5789-0.90801c-1.5906 0-2.1871 0.90801-3.5789 0.90801-1.4912 0.10089-2.5848-1.5134-3.4795-2.9258-1.8889-2.9258-3.3801-8.1721-1.3918-11.703 0.99415-1.7151 2.6842-2.9258 4.5731-2.9258 1.3918 0 2.7836 1.0089 3.5789 1.0089 0.89474 0 2.4854-1.2107 4.1754-1.1098 0.69591 0 2.6842 0.30267 3.9766 2.3205-0.09942 0.10089-2.386 1.5134-2.386 4.4392 0 3.5312 2.883 4.641 2.9825 4.7418 0 0.20178-0.39766 1.7151-1.4912 3.3294z"
        fill="#9b9b9b"
        strokeWidth="1.0015"
      />
    </IconHelper>
  ) : (
    <IconHelper {...otherProps} defaultWidth={23} defaultHeight={30}>
      <path
        d="m13.002 2.2255c1.0208-1.2797 2.5757-2.1967 4.23-2.2255 0.17767 2.9562-1.6846 6.2418-4.6971 6.8809-1.4018 0.5987-1.2123-0.96541-0.93368-1.8709 0.27179-1.0114 0.77346-1.9523 1.4008-2.7845z"
        fill="#fff"
        strokeWidth="1.2935"
      />
      <path
        d="m21.077 26.315c-1.0016 1.5423-2.1611 3.4831-4.1798 3.6603-1.8287 0.14072-3.3114-1.4388-5.163-1.1597-1.7577 0.10434-3.2911 1.5994-5.1091 1.0729-1.988-0.71132-2.995-2.7748-4.0713-4.4503-2.0238-3.7189-3.2076-8.1857-2.1713-12.39 0.79234-3.2074 3.78-6.1432 7.2269-5.8146 1.5279 0.12119 2.8093 1.2424 4.3371 1.2592 1.8466-0.47686 3.5747-1.6909 5.5666-1.4005 1.921 0.19348 3.7009 1.3431 4.7177 2.9891-2.3219 1.5752-3.5746 4.5742-2.9165 7.335 0.39425 2.0196 1.869 3.695 3.6856 4.5784-0.31 1.5501-1.0674 3.0077-1.9228 4.3199z"
        fill="#fff"
        strokeWidth="1.2935"
      />
    </IconHelper>
  );
};

export default IosIcon;
