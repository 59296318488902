import IconHelper from "./IconHelper";

const CreateOfferIcon = (props) => (
  <IconHelper {...props} defaultWidth={22} defaultHeight={22}>
    <g transform="translate(-.88)" fill="none" fillRule="evenodd">
      <path
        id="Shape"
        d="m11.88 0c-6.0323 0-11 4.9677-11 11s4.9677 11 11 11 11-4.9677 11-11-4.9677-11-11-11zm0.44355 5.6774h-0.8871v4.879h-4.879v0.8871h4.879v4.879h0.8871v-4.879h4.879v-0.8871h-4.879z"
        fill="#fff"
        strokeWidth="1.0417"
      />
    </g>
  </IconHelper>
);

export default CreateOfferIcon;
