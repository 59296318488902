import { useMutation, useQuery } from "@apollo/client";
import { useUserStore } from "store/user";
import { ID } from "types/misc";
import GET_ADVERTISER from "./get";
import GET_ALL_ADVERTISERS from "./get-all";
import PUT_ADVERTISER from "./put";
import { Advertiser } from "./types";

interface GetAllData {
  advertisers: Advertiser[];
}

interface GetAllVariables {}

export function useGetAllAdvertisers() {
  const isAuthenticated = useUserStore((store) => store.isAuthenticated);
  return useQuery<GetAllData, GetAllVariables>(GET_ALL_ADVERTISERS, {
    fetchPolicy: (window as any).Cypress ? "cache-first" : "cache-and-network",
    skip: !isAuthenticated,
  });
}

interface GetData {
  advertiser: Advertiser;
}

interface GetVariables {
  id: ID;
}

export function useGetAdvertiser(variables) {
  return useQuery<GetData, GetVariables>(GET_ADVERTISER, {
    variables,
    skip: !variables.id,
  });
}

interface PutData {
  advertiserUpdate: {
    advertiser: Advertiser;
    errors: string[];
  };
}

type PutVariables = Partial<Advertiser>;

export function usePutAdvertiser() {
  const [mutate, response] = useMutation<PutData, PutVariables>(
    PUT_ADVERTISER,
    {
      refetchQueries: ({ data }: { data: PutData }) => {
        const { advertiser, errors } = data.advertiserUpdate ?? {};
        return errors.length
          ? []
          : [
              {
                query: GET_ADVERTISER,
                variables: {
                  id: advertiser.id,
                },
              },
              { query: GET_ALL_ADVERTISERS },
            ];
      },
    },
  );
  return { mutate, ...response };
}
