import { DateUtils } from "./date";

/**
 * Subtract 1 as first day's time is handled with 00:00:00.000 and
 * last day's with 23:59:59.999
 */
const MAX_PAST_DAYS = 90 - 1;

export const getMinPossibleQueryDate = (): Date => {
  const minDate = DateUtils.getUTCTodayWithoutTime();
  minDate.setDate(minDate.getDate() - MAX_PAST_DAYS);
  return minDate;
};

export const getQueryDateSeconds = (
  date: Date,
  isEndDate?: boolean,
): number => {
  const dateValid = DateUtils.isDateValid(date);

  if (isEndDate && !dateValid) return null;

  let milliseconds = DateUtils.getMillisecondsFromFakedUTCMilliseconds(
    dateValid ? date?.getTime() : getMinPossibleQueryDate().getTime(),
  );

  if (isEndDate) {
    // set the time to 1 second before midnight
    milliseconds += 86400000 - 1000;
  }

  return Math.floor(milliseconds / 1000);
};
