import IconHelper from "./IconHelper";

const CaretDownIcon = (props) => {
  return (
    <IconHelper {...props} defaultWidth={12} defaultHeight={7.4}>
      <g transform="translate(-44.016 -190.45)">
        <path
          d="m44.016 191.85 6 6 6-6-1.4-1.4-4.5999 4.5999-4.5999-4.5999z"
          fill="#9b9b9f"
          strokeMiterlimit="0"
          strokeWidth="0"
        />
      </g>
    </IconHelper>
  );
};

export default CaretDownIcon;
