import { ReactNode } from "react";
import create from "zustand";

export interface Store {
  currentPageId: string;
  breadcrumbItems: ReactNode[];
  navMenuOpen: boolean;

  setCurrentPageId: (currentPageId: string) => void;
  setBreadcrumbItems: (breadcrumbItems: ReactNode[]) => void;
  setNavMenuOpen: (navMenuOpen: boolean) => void;
}

export const usePageStore = create<Store>((set) => ({
  currentPageId: "",
  breadcrumbItems: [],
  navMenuOpen: false,

  setCurrentPageId: (currentPageId: string) => set(() => ({ currentPageId })),
  setBreadcrumbItems: (breadcrumbItems: ReactNode[]) =>
    set(() => ({ breadcrumbItems })),
  setNavMenuOpen: (navMenuOpen: boolean) => set(() => ({ navMenuOpen })),
}));
