import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import clsx from "clsx";
import { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { usePageStore } from "store/page";
import HtmlTooltip from "../UI/HtmlTooltip/HtmlTooltip";
import styles from "./styles";

const useStyles = makeStyles(styles);

/**
 * Strips parameter placeholders from path or adjusts path by item's function, e.g. by user role.
 *
 * @param {object} routeItem Private route entry.
 */
const getNavDestination = (routeItem) => {
  if (routeItem.getNavLink) {
    return routeItem.getNavLink(routeItem.path);
  }

  if (!routeItem.path) {
    return "";
  }

  const index = routeItem.path.indexOf("/:");
  return index !== -1 ? routeItem.path.substring(0, index) : routeItem.path;
};

const NavItem = (props) => {
  const { closeMenu, subMenuOpened, setSubMenuOpened, navMenuOpen, item } =
    props;

  const classes = useStyles();
  const currentPageId = usePageStore((state) => state.currentPageId);

  const handleItemClick = (isSubRoute) => {
    if (isSubRoute) {
      closeMenu && closeMenu();
    } else {
      setSubMenuOpened(!subMenuOpened);
    }
  };

  const parentListItem = () => {
    const clickHandler = item.subRoutes ? handleItemClick : () => {};

    return (
      <ListItem
        button
        selected={props.selected}
        onClick={clickHandler}
        data-cy={`${
          item.id ? String(item.id).toLowerCase() : "unknown"
        }_menu_item`}
      >
        {item.icon ? (
          <ListItemIcon className={classes.navIcon}>{item.icon}</ListItemIcon>
        ) : null}
        <ListItemText
          primary={item.caption}
          classes={{ primary: classes.navItemText }}
          className={navMenuOpen ? null : classes.navHidden}
        />
        {navMenuOpen && item.subRoutes ? (
          subMenuOpened ? (
            <ExpandLess color="primary" />
          ) : (
            <ExpandMore color="primary" />
          )
        ) : null}
      </ListItem>
    );
  };

  const parentEntry =
    item.path && !item.subRoutes ? (
      item.external ? (
        <a
          href={item.path}
          style={{ textDecoration: "none" }}
          className={classes.navlink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {parentListItem()}
        </a>
      ) : (
        <NavLink
          to={getNavDestination(item)}
          exact={item.exact}
          className={classes.navlink}
        >
          {parentListItem()}
        </NavLink>
      )
    ) : (
      parentListItem()
    );

  const getLinkContent = (item) => {
    const selected = currentPageId === item.id;
    return (
      <>
        <div
          className={
            selected
              ? clsx(classes.subMenuSelector, classes.subMenuSelectedIndicator)
              : classes.subMenuSelector
          }
        ></div>
        <ListItem
          className={
            props.selected
              ? clsx(classes.subListItem, classes.subListItemParentSelected)
              : classes.subListItem
          }
          button
          selected={selected}
          onClick={() => handleItemClick(true)}
        >
          <ListItemText
            primary={item.caption}
            classes={{
              primary: [
                classes.navItemText,
                selected
                  ? classes.subListItemTextSelected
                  : classes.subListItemText,
              ].join(" "),
            }}
          />
        </ListItem>
      </>
    );
  };

  const getLink = (item) => {
    const index = item.path.indexOf("/:");
    const where = index !== -1 ? item.path.substring(0, index) : item.path;

    return item.external ? (
      <a
        href={item.path}
        style={{ textDecoration: "none" }}
        className={classes.navlink}
        target="_blank"
        rel="noopener noreferrer"
      >
        {getLinkContent(item)}
      </a>
    ) : (
      <NavLink
        key={item.id}
        to={where}
        exact={item.exact}
        className={classes.navlink}
      >
        {getLinkContent(item)}
      </NavLink>
    );
  };

  const subMenu = item.subRoutes ? (
    <Collapse in={subMenuOpened} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {item.subRoutes.map((item) => getLink(item))}
      </List>
    </Collapse>
  ) : null;

  const mainEntry =
    navMenuOpen || !item.caption ? (
      parentEntry
    ) : (
      <HtmlTooltip title={item.caption} placement="right">
        {parentEntry}
      </HtmlTooltip>
    );

  return (
    <Fragment key={item.id}>
      {mainEntry}
      {subMenu}
    </Fragment>
  );
};

export default NavItem;
