import IconHelper from "./IconHelper";

const DashboardIcon = (props) => (
  <IconHelper {...props} defaultWidth={20} defaultHeight={19.9}>
    <g
      id="Dasboard"
      transform="matrix(1.0028 0 0 1.0025 -.78795 -.78765)"
      fill="none"
      fillRule="evenodd"
      stroke="#fff"
    >
      <path d="m20.429 9.5472 3e-6 0.00117c-0.00801-2.0603-0.76265-4.0453-2.125-5.5911-1.6146-1.8262-3.9319-2.8716-6.3674-2.8716-0.14453 0-0.25835 0.11376-0.25835 0.25811v8.2324c0 0.14434 0.11382 0.25811 0.25835 0.25811h8.234c0.14453 0 0.25835-0.11376 0.25835-0.25811zm-2.6168 2.0408h-7.9758v-7.9742c0-0.14439-0.11387-0.25824-0.25835-0.25824-4.6813 0-8.4924 3.8103-8.4924 8.4907 0 4.6803 3.8111 8.4905 8.4924 8.4905 4.6814 0 8.4925-3.8103 8.4925-8.4905 0-0.14032-0.11796-0.25824-0.25835-0.25824z" />
    </g>
  </IconHelper>
);

export default DashboardIcon;
