import { CSSProperties } from "react";
import { Product } from "types/product";
import noUrlImage from "../images/no_app.png";
import PlatformIcon from "./PlatformIcon";

interface Props {
  product: Product;
  size?: number;
  marginRight?: number;
}

export default function ProductWithPlatformIcon(props: Props) {
  const { product, marginRight, size = 27 } = props;

  let platform = product.platform;
  if (product.samsungStore) {
    platform = "samsung";
  }

  let iconUrl = noUrlImage;
  if (product.iconUrl) {
    iconUrl = getIconUrlPrefix(product.iconUrl) + product.iconUrl;
  }

  const iconBgSize = (size * 25) / 27;
  const iconSize = (size * 14) / 27;
  const iconPosition = size - iconSize;

  return (
    <div style={getContainerStyles(size, marginRight)}>
      <div style={getIconWrapperStyles(iconBgSize, iconUrl)} />
      <PlatformIcon
        platform={platform}
        size={iconSize}
        style={{
          position: "absolute",
          top: iconPosition,
          left: iconPosition,
        }}
      />
    </div>
  );
}

function getIconUrlPrefix(url) {
  return process.env.REACT_APP_API_URL.indexOf("http://localhost") === 0 &&
    url.indexOf("http") !== 0
    ? process.env.REACT_APP_API_URL
    : "";
}

function getContainerStyles(size, marginRight): CSSProperties {
  return {
    height: size,
    width: size,
    minWidth: size,
    display: "inline-block",
    position: "relative",
    marginRight,
  };
}

function getIconWrapperStyles(size: number, iconUrl: string): CSSProperties {
  return {
    height: size,
    width: size,
    background: `url(${iconUrl}) 0 0 / ${size}px ${size}px no-repeat`,
    borderRadius: "30%",
    top: 0,
    left: 0,
    display: "block",
    position: "absolute",
  };
}
