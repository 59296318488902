import { DateUtils } from "utils/date";
import { getQueryDateSeconds } from "utils/queryDateUtils";
import create from "zustand";

// store for global start and end date

export type DateRange = [Date?, Date?];

interface Store {
  startDateSeconds: number;
  endDateSeconds: number;
  dateRange: DateRange;
  setFromDateRange: (datRange: DateRange) => void;
}

export const useDateRangeStore = create<Store>((set) => ({
  startDateSeconds: getQueryDateSeconds(DateUtils.getUTCTodayWithoutTime()),
  endDateSeconds: getQueryDateSeconds(DateUtils.getUTCTodayWithoutTime()),

  dateRange: [
    DateUtils.getUTCTodayWithoutTime(),
    DateUtils.getUTCTodayWithoutTime(),
  ],

  setFromDateRange: (dateRange) =>
    set(() => {
      const startDateSeconds = getQueryDateSeconds(dateRange[0]);
      const endDateSeconds = getQueryDateSeconds(dateRange[1], true);
      return { startDateSeconds, endDateSeconds, dateRange };
    }),
}));
