import { gql } from "@apollo/client";

const PUT_PRODUCT = gql`
  mutation productUpdate($id: ID!, $name: String, $enabled: Boolean) {
    productUpdate(
      input: { id: $id, attributes: { name: $name, enabled: $enabled } }
    ) {
      errors
      product {
        id
        advertiserId
        bundleId
        enabled
        iconUrl
        name
        platform
        samsungStore
      }
    }
  }
`;

export default PUT_PRODUCT;
