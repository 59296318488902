import { colors } from "theme";
import { NAV_MENU_WIDTH, TOP_APP_BAR_HEIGHT } from "../../utils/constants";

const styles = () => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    width: 28,
    borderRadius: 5,
    "&:hover": {
      backgroundColor: colors.gray[100],
    },
  },
  listItemRoot: {
    minHeight: 48,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#00000014",
    },
  },
  listItemRootDisabled: {
    backgroundColor: colors.gray[100],
    "&:hover": {
      backgroundColor: "#00000014",
    },
  },
  itemCount: {
    fontWeight: 600,
  },
  textWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: 3,
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
  entryText: {
    fontSize: 14,
  },
  typeText: {
    fontSize: 12,
    lineHeight: "14px",
    fontWeight: 300,
  },
  backdrop: (props) => {
    const left = props.navMenuOpen
      ? NAV_MENU_WIDTH.open
      : NAV_MENU_WIDTH.closed;
    return {
      position: "fixed",
      zIndex: 10,
      left: left,
      width: `calc(100% - ${left}px)`,
      top: TOP_APP_BAR_HEIGHT,
      height: `calc(100% - ${TOP_APP_BAR_HEIGHT}px)`,
      overflow: "auto",
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    };
  },
  selectArea: {
    position: "relative",
    width: 455,
    height: "100%",
    borderTop: `1px solid ${colors.gray[200]}`,
    padding: "0 23px 23px 23px",
    backgroundColor: colors.white,
    overflowY: "auto",
  },
  searchInput: {
    position: "sticky",
    paddingTop: 23,
    top: 0,
    backgroundColor: colors.white,
    zIndex: 1,
  },
  entryWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  loadingIndicator: {
    position: "absolute",
    left: "50%",
    top: "50%",
  },
});

export default styles;
