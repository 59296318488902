import {
  IconButton,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { AccountCircleSharp } from "@material-ui/icons";
import { DesktopDateRangePicker } from "@material-ui/pickers";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import ClearSearchIconButton from "components/UI/Button/ClearSearchIconButton";
import AdvertiserDropdown from "containers/Dashboard/AdvertiserDropdown";
import DashboardLink from "containers/Dashboard/DashboardLink";
import OfferDropdown from "containers/Dashboard/OfferDropdown";
import ProductDropdown from "containers/Dashboard/ProductDropdown";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDateRangeStore } from "store/date-range";
import { usePageStore } from "store/page";
import api from "utils/api";
import {
  PageIdsWithDateRange,
  TOP_APP_BAR_COMPONENTS,
  TOP_APP_BAR_HEIGHT,
} from "utils/constants";
import { DATA_PATHS } from "utils/dataPaths";
import { logoutUserStorage } from "utils/logoutUserStorage";
import { getMinPossibleQueryDate } from "utils/queryDateUtils";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import BreadcrumbItem from "../Breadcrumb/BreadcrumbItem";
import BreadcrumbItemDivider from "../Breadcrumb/BreadcrumbItemDivider";
import styles from "./AppBarStyles";

const staticDateAdapter = new DateFnsAdapter({});

const AppBar = (props) => {
  const { classes, navMenuOpen, entries } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const [pageTitle, setPageTitle] = useState(null);

  const dateRange = useDateRangeStore((state) => state.dateRange);
  const setFromDateRange = useDateRangeStore((state) => state.setFromDateRange);

  const [rangeSelectorClosed, setRangeSelectorClosed] = useState(0);
  const [dateRangeValue, setDateRangeValue] = useState(dateRange);

  const currentPageId = usePageStore((state) => state.currentPageId);
  const breadcrumbItems = usePageStore((state) => state.breadcrumbItems);

  /**
   * Propagates final changes to store which causes affected queries to be executed.
   * This is only called when date selection is finished or a date field is cleared.
   *
   * @param {[Date?, Date?]} dateRange
   */
  const acceptDateRange = (dateRange) => {
    setFromDateRange(dateRange ?? dateRangeValue);
  };

  useEffect(() => {
    acceptDateRange();
  }, [rangeSelectorClosed]);

  const menuOpen = Boolean(anchorEl);

  async function logout() {
    try {
      logoutUserStorage();
      await api.delete(DATA_PATHS.LOGOUT_REST_DELETE);
      window.location.href = "/login";
    } catch (error) {
      window.location.href = "/login";
    }
  }

  useEffect(
    () => setPageTitle(getPageTitle(currentPageId)),
    [currentPageId, breadcrumbItems],
  );

  const hideMenu = () => setAnchorEl(null);

  const breadcrumbComponents = {
    [TOP_APP_BAR_COMPONENTS.NAV_LINK]: NavLink,
    [TOP_APP_BAR_COMPONENTS.DASHBOARD_LINK]: DashboardLink,
    [TOP_APP_BAR_COMPONENTS.OFFER_DROPDOWN]: OfferDropdown,
    [TOP_APP_BAR_COMPONENTS.PRODUCT_DROPDOWN]: ProductDropdown,
    [TOP_APP_BAR_COMPONENTS.ADVERTISER_DROPDOWN]: AdvertiserDropdown,
    [TOP_APP_BAR_COMPONENTS.BREADCRUMB_ITEM_DIVIDER]: BreadcrumbItemDivider,
    [TOP_APP_BAR_COMPONENTS.BREADCRUMB_ITEM]: BreadcrumbItem,
  };

  const getPageTitle = () => {
    if (breadcrumbItems && breadcrumbItems.length > 0) {
      let items = breadcrumbItems
        .filter((item) => item !== false)
        .map((items) => {
          if (!Array.isArray(items)) {
            items = [items];
          }

          return (
            <>
              {items.map((item, index) => {
                if (item.component) {
                  const TagName = breadcrumbComponents[item.component];
                  if (!TagName) {
                    return "";
                  }
                  return (
                    <TagName
                      key={index}
                      {...item.props}
                      navMenuOpen={navMenuOpen}
                    >
                      {item.children}
                    </TagName>
                  );
                } else {
                  // just a string
                  return item;
                }
              })}
            </>
          );
        });

      return (
        <Breadcrumb
          bottomBorder={false}
          items={items}
          height={TOP_APP_BAR_HEIGHT}
        />
      );
    }

    return entries.reduce((prev, cur) => {
      if (cur.id === currentPageId) {
        return (
          <Breadcrumb
            bottomBorder={false}
            items={[cur.caption]}
            height={TOP_APP_BAR_HEIGHT}
          />
        );
      }
      if (cur.subRoutes && cur.subRoutes.length > 0) {
        return (
          prev ||
          cur.subRoutes.reduce((prev, cur) => {
            return cur.id === currentPageId ? (
              <Breadcrumb
                bottomBorder={false}
                items={[cur.caption]}
                height={TOP_APP_BAR_HEIGHT}
              />
            ) : (
              prev
            );
          }, null)
        );
      }
      return prev;
    }, null);
  };

  const accountPath = entries.reduce((prev, cur) => {
    if (cur.isProfile) {
      return cur.path;
    }
    if (cur.subRoutes && cur.subRoutes.length > 0) {
      return cur.subRoutes.reduce((prev, cur) => {
        return cur.isProfile ? cur.path : prev;
      }, "/");
    }
    return prev;
  }, "/");

  return (
    <MuiAppBar elevation={0} position="relative" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Typography
          variant="h4"
          color="inherit"
          noWrap
          className={[classes.grow, classes.toolbarFont].join(" ")}
        >
          {pageTitle}
        </Typography>
        <div style={{ display: "flex", alignItems: "center" }}>
          {PageIdsWithDateRange.includes(currentPageId) && (
            <DesktopDateRangePicker
              // allowSameDateSelection - not working in alpha or documentation doesn't explain correct usage
              // clearable - not working in alpha or documentation doesn't explain correct usage
              minDate={getMinPossibleQueryDate()}
              disableFuture
              inputFormat={"yyyy-MM-dd"}
              mask={"____-__-__"}
              value={dateRangeValue}
              onChange={setDateRangeValue}
              onClose={() => {
                setRangeSelectorClosed((prev) => ++prev);
              }}
              renderInput={(startProps, endProps) => {
                // extract various unneeded input props, e.g. "error" because allowSameDateSelection seems not to work and marks input as wrong
                const {
                  label: startLabel,
                  variant: startVariant,
                  error: startError,
                  helperText: startHelperText,
                  ...newStartProps
                } = startProps;
                newStartProps.inputProps.placeholder = "Start date";
                const {
                  label: endLabel,
                  variant: endVariant,
                  error: endError,
                  helperText: endHelperText,
                  ...newEndProps
                } = endProps;
                newEndProps.inputProps.placeholder = "End date";

                return (
                  <>
                    <TextField
                      classes={{ root: classes.dateRange }}
                      {...newStartProps}
                      InputProps={{
                        "data-testid": "start-date",
                        placeholder: "Start date",
                        endAdornment: (
                          <ClearSearchIconButton
                            onClick={() => {
                              setDateRangeValue([null, dateRangeValue[1]]);
                              acceptDateRange([null, dateRangeValue[1]]);
                            }}
                          />
                        ),
                      }}
                    />
                    <div
                      style={{
                        padding: "0 7px",
                        textAlign: "center",
                      }}
                    >
                      to
                    </div>
                    <TextField
                      classes={{ root: classes.dateRange }}
                      {...newEndProps}
                      InputProps={{
                        "data-testid": "end-date",
                        endAdornment: (
                          <ClearSearchIconButton
                            onClick={() => {
                              setDateRangeValue([dateRangeValue[0], null]);
                              acceptDateRange([dateRangeValue[0], null]);
                            }}
                          />
                        ),
                      }}
                    />
                  </>
                );
              }}
              dateAdapter={staticDateAdapter}
            />
          )}
          <IconButton
            data-cy="profile_button"
            aria-owns={menuOpen ? "menu-appbar" : undefined}
            aria-haspopup="true"
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            <AccountCircleSharp />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={menuOpen}
            onClose={hideMenu}
          >
            <MenuItem>
              <NavLink
                onClick={hideMenu}
                className={classes.navlinkMenu}
                to={accountPath}
              >
                Account
              </NavLink>
            </MenuItem>
            <MenuItem
              className={classes.navlinkMenu}
              onClick={logout}
              data-cy="profile_logout_button"
            >
              Logout
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </MuiAppBar>
  );
};

export default withStyles(styles, { withTheme: true })(AppBar);
