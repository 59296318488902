import { gql } from "@apollo/client";

const POST_OFFER_CAMPAIGN = gql`
  mutation offerCampaignCreate(
    $productId: ID!
    $igniteCampaignId: ID
    $icon: IconInput
    $name: String!
    $comment: String
    $attributionProvider: AttributionProviderEnum!
    $actionType: ActionTypeEnum!
    $title: String
    $adContent: AdContentEnum!
    $clickUrl: String!
    $complexity: Int!
    $bid: Float!
    $startDate: BigInt
    $endDate: BigInt
    $dynamicPayouts: Boolean
    $capping: CappingInput!
    $minimumLatency: Int
    $attributionWindow: Int
    $multiPayout: Boolean
    $instructionSteps: [String!]
    $offerSteps: [OfferStepCreateInput!]
    $deviceTargeting: DeviceTargetingInput!
    $locationTargeting: LocationTargetingInput!
    $applicationTargeting: ApplicationTargetingInput!
    $offerTranslationsAttributes: [OfferTranslationCreateInput!]
    $creatives: [OfferCampaignCreativeCreateInput!]
  ) {
    offerCampaignCreate(
      input: {
        attributes: {
          productId: $productId
          icon: $icon
          name: $name
          comment: $comment
          actionType: $actionType
          attributionProvider: $attributionProvider
          bid: $bid
          title: $title
          adContent: $adContent
          igniteCampaignId: $igniteCampaignId
          clickUrl: $clickUrl
          complexity: $complexity
          startDate: $startDate
          endDate: $endDate
          minimumLatency: $minimumLatency
          attributionWindow: $attributionWindow
          multiPayout: $multiPayout
          instructionSteps: $instructionSteps
          offerSteps: $offerSteps
          dynamicPayouts: $dynamicPayouts
          capping: $capping
          deviceTargeting: $deviceTargeting
          locationTargeting: $locationTargeting
          applicationTargeting: $applicationTargeting
          offerTranslationsAttributes: $offerTranslationsAttributes
          creatives: $creatives
        }
      }
    ) {
      offerCampaign {
        id
        productId
      }
      errors
    }
  }
`;

export default POST_OFFER_CAMPAIGN;
