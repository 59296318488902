import { withStyles } from "@material-ui/core/styles";
import { colors } from "theme";

const styles = () => ({
  item: {
    display: "flex",
    alignItems: "center",

    paddingLeft: (props) => (props.height ? props.height / 10 : 20),
    paddingRight: (props) => (props.height ? props.height / 10 : 10),

    fontSize: (props) => props.fontSize || 18,
    fontWeight: 400,
    whiteSpace: "nowrap",
    cursor: "default",
    color: colors.softBlack,
    "&:first-child": {
      paddingLeft: (props) => (props.height ? (24.0 * props.height) / 65 : 24),
    },
  },
});

const BreadcrumbItemWrapper = (props) => {
  const { classes, item, ...rest } = props;

  return (
    <div className={classes.item} {...rest}>
      {item}
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(BreadcrumbItemWrapper);
