import {
  CircularProgress,
  ClickAwayListener,
  Fade,
  InputAdornment,
  ListItem,
  ListItemText,
  TextField,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { SearchSharp } from "@material-ui/icons";
import clsx from "clsx";
import { useGetAllProducts } from "graphql/product/hooks";
import useShowToast from "hooks/useShowToast";
import deburr from "lodash/deburr";
import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useUserStore } from "store/user";
import { colors } from "theme";
import { useDebouncedCallback } from "use-debounce";
import { closeDropdownById } from "utils/closeDropdownById";
import CaretDownIcon from "../../assets/icons/CaretDownIcon";
import ProductWithPlatformIcon from "../../assets/icons/ProductWithPlatformIcon";
import HtmlTooltip from "../../components/UI/HtmlTooltip/HtmlTooltip";
import { PAGE_PATHS } from "../../routes/routes";
import { DEBOUNCE_DELAY_LOCAL, PRODUCT_PLATFORM } from "../../utils/constants";
import styles from "./ProductOfferDropdownStyles";

const ProductDropdown = (props) => {
  const { classes, allItems, shownId } = props;
  const [open, setOpen] = useState(false);

  const advertiserId = useUserStore((state) => state.selectedAdvertiser.id);

  const { loading, error, data } = useGetAllProducts({
    advertiserId,
  });
  const products = data?.advertiserProducts;

  const { showError } = useShowToast();

  useEffect(() => {
    // reset state
    setRedirect(null);
    setOpen(false);
  }, [shownId]);

  const [filter, setFilter] = useState(""); // the "real" filter value
  const [filterText, setFilterText] = useState(""); // filter input text, will be set as filter after debounce delay

  const [allListItems, setAllListItems] = useState(allItems ? allItems : []);
  const [listItems, setListItems] = useState(allItems ? allItems : []);

  const [redirect, setRedirect] = useState(null);

  /**
   * Hook for debounced search field.
   */
  const debouncedFilter = useDebouncedCallback((value) => {
    setFilter(value);
  }, DEBOUNCE_DELAY_LOCAL);

  /**
   * Initiates debounced filter change.
   * @param {string} value
   */
  const onFilterChange = (value) => {
    setFilterText(value);
    debouncedFilter(value);
  };

  useEffect(() => {
    const compareFilter = deburr(filter.trim()).toLowerCase();

    const filteredItems = allListItems.filter((item) => {
      let result = deburr(item.name.trim().toLowerCase()).includes(
        compareFilter,
      );
      if (item.appId) {
        result =
          result ||
          deburr(item.appId.trim().toLowerCase()).includes(compareFilter);
      }
      if (item.bundleId) {
        result =
          result ||
          deburr(item.bundleId.trim().toLowerCase()).includes(compareFilter);
      }
      return result;
    });

    setListItems(filteredItems);
  }, [filter, allListItems]);

  useEffect(() => {
    if (!products) return;
    const items = products
      .filter((item) => {
        let result = item.id + "" !== shownId + "";
        return result;
      })
      .map((entry) => ({
        ...entry,
        platform: entry.samsungStore
          ? PRODUCT_PLATFORM.SAMSUNG
          : entry.platform,
      }));

    setListItems(items);
    setAllListItems(items);
  }, [setListItems, products, shownId]);

  const redirectTo = (id) => {
    setRedirect(`${PAGE_PATHS.productDetails.replace(":productId", id)}`);
  };

  if (error) {
    showError(error.message);
  }

  if (redirect) return <Redirect to={redirect} />;

  return (
    <>
      <HtmlTooltip
        title={"Switch App"}
        placement="bottom"
        fontSize={12}
        noShadow
        inverse
      >
        <div
          id="product-dropdown"
          className={classes.root}
          onClick={(e) => {
            closeDropdownById("offer-dropdown");
            e.stopPropagation();
            setOpen((open) => !open);
          }}
        >
          <CaretDownIcon
            height={7}
            color={colors.softBlack}
            style={{
              transform: open ? "rotate(180deg)" : null,
            }}
          ></CaretDownIcon>
        </div>
      </HtmlTooltip>

      <Fade in={open} timeout={{ enter: 300, exit: 250 }}>
        <div className={classes.backdrop}>
          <ClickAwayListener
            mouseEvent={open ? "onClick" : false}
            touchEvent={open ? "onTouchEnd" : false}
            onClickAway={() => open && setOpen(false)}
          >
            <div className={classes.selectArea}>
              {loading ? (
                <CircularProgress
                  size={40}
                  className={classes.loadingIndicator}
                />
              ) : null}

              <ListItem className={classes.searchInput} dense>
                <TextField
                  fullWidth
                  onChange={(e) => onFilterChange(e.target.value)}
                  InputProps={{
                    placeholder: "Search by App Name, Bundle ID or App ID",
                    value: filterText,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchSharp />
                      </InputAdornment>
                    ),
                  }}
                />
              </ListItem>

              <ListItem dense>
                <ListItemText
                  classes={{ primary: classes.itemCount }}
                  primary={`Apps (${listItems.length} of ${allListItems.length})`}
                />
              </ListItem>

              {listItems.map((item) => {
                const typeText =
                  item.platform === PRODUCT_PLATFORM.ANDROID
                    ? "Android"
                    : item.platform === PRODUCT_PLATFORM.WEB
                      ? "Web"
                      : item.platform === PRODUCT_PLATFORM.IOS
                        ? "iOS"
                        : null;

                const textElement = (
                  <div className={classes.textWrapper}>
                    <div className={classes.entryText}>{item.name}</div>
                    {typeText ? (
                      <div className={classes.typeText}>{typeText}</div>
                    ) : null}
                  </div>
                );

                return (
                  <ListItem
                    key={item.id}
                    dense
                    onClick={() => redirectTo(item.id)}
                    className={clsx({
                      [classes.listItemRoot]: true,
                      [classes.listItemRootDisabled]: !item.enabled,
                    })}
                    title={item.enabled ? null : "Disabled"}
                  >
                    <ProductWithPlatformIcon
                      product={item}
                      size={28}
                      marginRight={6}
                    />
                    <div className={classes.entryWrapper}>{textElement}</div>
                  </ListItem>
                );
              })}
            </div>
          </ClickAwayListener>
        </div>
      </Fade>
    </>
  );
};

ProductDropdown.defaultProps = {
  allItems: false,
};

export default withStyles(styles, { withTheme: true })(ProductDropdown);
