import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Breadcrumb, {
  DEFAULT_BREADCRUMB_HEIGHT,
} from "../components/Breadcrumb/Breadcrumb";
import styles from "./ModalStyles";

export const DEFAULT_MODAL_HEIGHT = 360;
export const DEFAULT_MODAL_WIDTH = 720;

const Modal = (props) => {
  const {
    "data-cy": dataCy,
    height,
    breadcrumbItems,
    breadcrumbControls,
    breadcrumbHeight,
    backdropClickHandler,
    children,
    classes,
  } = props;

  const bcHeight =
    breadcrumbItems || breadcrumbControls
      ? breadcrumbHeight || DEFAULT_BREADCRUMB_HEIGHT
      : 0;
  const modalContentHeight = (height || DEFAULT_MODAL_HEIGHT) - bcHeight;

  return (
    <div className={classes.backdrop} onClick={backdropClickHandler}>
      <div
        data-cy={dataCy}
        className={classes.root}
        onClick={(e) => e.stopPropagation()}
        role="dialog"
      >
        {(breadcrumbItems || breadcrumbControls) && (
          <Breadcrumb
            items={breadcrumbItems}
            controls={breadcrumbControls}
            height={bcHeight}
          />
        )}
        <div
          className={classes.content}
          style={{ minHeight: modalContentHeight }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  breadcrumbHeight: PropTypes.number,
  contentTopBorder: PropTypes.bool,
};

Modal.defaultProps = {
  contentTopBorder: false,
};

export default withStyles(styles, { withTheme: true })(Modal);
