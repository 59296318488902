import { IconButton } from "@material-ui/core";
import PropTypes from "prop-types";
import CloseIcon from "../../../assets/icons/CloseIcon";

const CloseIconButton = (props) => {
  const { color, ...rest } = props;
  return (
    <IconButton {...rest}>
      <CloseIcon color={color} />
    </IconButton>
  );
};

CloseIconButton.propTypes = {
  size: PropTypes.oneOf(["small", "medium"]), // "medium" is default
  onClick: PropTypes.func,
};

export default CloseIconButton;
