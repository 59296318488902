import { createStyles } from "@material-ui/styles";
import { colors } from "theme";
import { NAV_MENU_WIDTH, TOP_APP_BAR_HEIGHT } from "utils/constants";

const styles = () =>
  createStyles<any, { navMenuOpen: boolean }>({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: 40,
      width: 28,
      borderRadius: 5,
      "&:hover": {
        backgroundColor: colors.gray[100],
      },
    },
    listItemRoot: {
      height: 48,
      "&:hover": {
        backgroundColor: "#00000014",
      },
    },
    typeText: { fontSize: 12, lineHeight: "14px", fontWeight: 300 },
    backdrop: ({ navMenuOpen }) => {
      const left = navMenuOpen ? NAV_MENU_WIDTH.open : NAV_MENU_WIDTH.closed;
      return {
        left,
        position: "fixed",
        zIndex: 10,
        width: `calc(100% - ${left}px)`,
        top: TOP_APP_BAR_HEIGHT,
        height: `calc(100% - ${TOP_APP_BAR_HEIGHT}px)`,
        overflow: "auto",
        backgroundColor: "rgba(0, 0, 0, 0.2)",
      };
    },
    selectArea: {
      position: "relative",
      width: 455,
      height: "100%",
      borderTop: `1px solid ${colors.gray[300]}`,
      padding: "0 23px 23px 23px",
      backgroundColor: colors.white,
      overflowY: "auto",
    },
    searchInput: {
      position: "sticky",
      paddingTop: 23,
      top: 0,
      backgroundColor: colors.white,
      zIndex: 1,
    },
  });

export default styles;
