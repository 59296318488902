import { ErrorBoundary, Provider } from "@rollbar/react";
import { useGetAllAdvertisers } from "graphql/advertiser/hooks";
import useShowToast from "hooks/useShowToast";
import { Suspense, lazy, useEffect } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
  useLocation,
} from "react-router-dom";
import { useUserStore } from "store/user";
import api from "utils/api";
import { logoutUserStorage } from "utils/logoutUserStorage";
import { rollbarConfig } from "utils/rollbar";
import LoadingIndicator from "./components/UI/LoadingIndicator/LoadingIndicator";
import Layout from "./hoc/Layout";
import { PrivateRoute } from "./routes/PrivateRoute";
import { PAGE_PATHS, routes } from "./routes/routes";
import { DATA_PATHS } from "./utils/dataPaths";

const Login = lazy(
  () => import(/* webpackChunkName: "Login" */ "./containers/Auth/Login"),
);
const Password = lazy(
  () => import(/* webpackChunkName: "Password" */ "./containers/Auth/Password"),
);
const Register = lazy(
  () => import(/* webpackChunkName: "Register" */ "./containers/Auth/Register"),
);

export default function App() {
  const { showError } = useShowToast();

  const { data: availableAdvertisers } = useGetAllAdvertisers();

  const setUser = useUserStore((state) => state.setUser);
  const setToken = useUserStore((state) => state.setToken);
  const setIsAuthenticated = useUserStore((state) => state.setIsAuthenticated);

  const advertiserId = useUserStore((state) => state.selectedAdvertiser?.id);
  const selectedAdvertiser = useUserStore((state) => state.selectedAdvertiser);
  const setSelectedAdvertiser = useUserStore(
    (state) => state.setSelectedAdvertiser,
  );

  const query = new URLSearchParams(useLocation().search);
  const code = query.get("code");
  const state = query.get("state");

  useEffect(() => {
    if (!code || !state) return;

    async function getUser() {
      try {
        const res = await api.get(DATA_PATHS.OKTA_CALLBACK_GET, {
          params: { code, state },
        });

        if (!res) return;
        if (res.data.password === null) {
          logoutUserStorage();
          window.location.href = PAGE_PATHS.login; // setRedirect will not work here as state will be kept
        }

        const { data, headers } = res;
        setUser(data);
        setToken(headers.authorization);
        setIsAuthenticated(true);
        localStorage.setItem("token", headers.authorization);
        localStorage.setItem("user", JSON.stringify(data));
        window.location.href = PAGE_PATHS.dashboard;
      } catch (error) {
        showError(error.displayMessage);
      }
    }

    getUser();
  }, [code, state]);

  useEffect(() => {
    if (!availableAdvertisers?.advertisers?.length) return;

    if (!selectedAdvertiser?.name && advertiserId) {
      setSelectedAdvertiser(
        availableAdvertisers.advertisers.find(
          (item) => item.id === advertiserId,
        ),
      );
    }

    if (!selectedAdvertiser?.name && !advertiserId) {
      setSelectedAdvertiser(availableAdvertisers.advertisers[0]);
    }
  }, [advertiserId, availableAdvertisers]);

  if (code && state) return <LoadingIndicator></LoadingIndicator>;

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <Router>
          <Suspense fallback={<div></div>}>
            <Switch>
              <Route path={PAGE_PATHS.login} component={Login} />
              <Route path={PAGE_PATHS.password} component={Password} />
              <Route path={PAGE_PATHS.register} component={Register} />

              <Layout entries={routes}>
                <Suspense fallback={<div></div>}>
                  <Switch>
                    {/* Route components need to be direct children of Switch */}
                    {routes?.map((item) => (
                      <PrivateRoute
                        exact={item.exact}
                        path={item.path}
                        component={item.component}
                        modal={item.modal}
                        key={item.id}
                      />
                    ))}
                    <Redirect to={PAGE_PATHS.dashboard} />
                  </Switch>
                </Suspense>
              </Layout>
            </Switch>
          </Suspense>
        </Router>
      </ErrorBoundary>
    </Provider>
  );
}
