import { gql } from "@apollo/client";

const GET_PRODUCT = gql`
  query product($id: ID!) {
    product(id: $id) {
      id
      iconUrl
      url
      name
      platform
      samsungStore
    }
  }
`;

export default GET_PRODUCT;
