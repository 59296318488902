import { IconButton } from "@material-ui/core";
import PropTypes from "prop-types";
import ClearSearchIcon from "../../../assets/icons/ClearSearchIcon";

const ClearSearchIconButton = (props) => {
  return (
    <IconButton onClick={props.onClick} size={"small"}>
      <ClearSearchIcon />
    </IconButton>
  );
};

ClearSearchIconButton.propTypes = {
  onClick: PropTypes.func,
};

export default ClearSearchIconButton;
