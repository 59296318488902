import IconHelper from "./IconHelper";

const ExpandCollapseMenuIcon = (props) => {
  const { expand, ...rest } = props;
  return expand ? (
    <IconHelper {...rest} defaultWidth={18} defaultHeight={10}>
      <path d="m0 8v2h10v-2z" fill="#fff" />
      <path d="m0 4v2h10v-2z" fill="#fff" />
      <path d="m14 1v8l4-4z" fill="#fff" />
      <path d="m0 0v2h10v-2z" fill="#fff" />
    </IconHelper>
  ) : (
    <IconHelper {...rest} defaultWidth={18} defaultHeight={10}>
      <path
        d="m18 1v8l-4-4zm-8 9h-10v-2h10zm0-8h-10v-2h10zm0 4h-10v-2h10z"
        fill="#fff"
      />
    </IconHelper>
  );
};

export default ExpandCollapseMenuIcon;
