import IconHelper from "./IconHelper";

const AndroidIcon = (props) => {
  const { outlined, ...otherProps } = props;

  return outlined ? (
    <IconHelper {...otherProps} defaultWidth={36} defaultHeight={36}>
      <rect
        x="5e-7"
        y="5e-7"
        width="36"
        height="36"
        ry="6.9765"
        fill="#fff"
        strokeWidth="0"
      />
      <g
        transform="matrix(.99415 0 0 1.0089 -.85807 -1.7402)"
        strokeWidth="5.4"
      >
        <g strokeWidth="5.4">
          <path
            d="m21.569 13.116c-0.3 0-0.6 0.2-0.6 0.5s0.3 0.6 0.6 0.6 0.6-0.2 0.6-0.6c0-0.3-0.2-0.5-0.6-0.5zm8.6-10.4h-22.4c-3.2 0-5.9 2.6-5.9 5.9v21.9c0 3.2 2.6 5.9 5.9 5.9h22.4c3.2 0 5.9-2.6 5.9-5.9v-21.9c0-3.3-2.7-5.9-5.9-5.9zm-17.7 20.3c0 0.7-0.6 1.2-1.3 1.2h-0.1c-0.7 0-1.3-0.6-1.3-1.2v-5.4c0-0.7 0.6-1.2 1.3-1.2h0.1c0.7 0 1.3 0.6 1.3 1.2zm12.122 2.4c0 0.6-0.5 1.1-1.1 1.1h-1.1219v3c0 0.7-0.6 1.2-1.2 1.2h-0.1c-0.7 0-1.2-0.6-1.2-1.2v-2.9h-1.7v2.9c0 0.7-0.6 1.2-1.2 1.2h-0.1c-0.7 0-1.2-0.6-1.2-1.2v-2.9h-1.2c-0.6 0-1.1-0.5-1.1-1.1v-8.5l11.209 0.02846c0 4.4547 0.0131 5.8828 0.0131 8.3715zm0.17812-9.3h-11.7v-0.3c0-1.8 1.2-3.4 3-4.3l-1-1.5c-0.1-0.1 0-0.2 0.1-0.3s0.2 0 0.3 0.1l1 1.5c0.8-0.3 1.6-0.5 2.5-0.5s1.8 0.2 2.5 0.5l1-1.5c0.1-0.1 0.2-0.1 0.3-0.1 0.1 0.1 0.1 0.2 0.1 0.3l-1 1.5c1.8 0.8 2.9141 2.3844 2.9141 4.2844-0.01016 0.15078-0.01406 0.21562-0.01406 0.31562zm3.3812 6.9c0 0.7-0.6 1.2-1.3 1.2h-0.1c-0.7 0-1.3-0.6-1.3-1.2v-5.4c0-0.7 0.6-1.2 1.3-1.2h0.1c0.7 0 1.3 0.6 1.3 1.2zm-11.981-9.9c-0.3 0-0.6 0.2-0.6 0.5s0.3 0.6 0.6 0.6 0.6-0.2 0.6-0.6c0-0.3-0.3-0.5-0.6-0.5z"
            fill="#a4c639"
            strokeWidth="5.4"
          />
        </g>
      </g>
    </IconHelper>
  ) : (
    <IconHelper {...otherProps} defaultWidth={26} defaultHeight={30}>
      <path
        d="m7.7441 0.0011524c-0.03535 0.004452-0.07023 0.02232-0.10559 0.057967-0.14143 0.14258-0.28315 0.28526-0.14171 0.42785l1.4143 2.1365c-2.5458 1.2833-4.243 3.5668-4.243 6.1334v0.42784h16.55c0-0.14258 0.0051-0.2377 0.01945-0.45269 0-2.7091-1.5777-4.9678-4.1235-6.1085l1.4144-2.1365c0-0.14258-2.7e-4 -0.28526-0.14171-0.42785-0.14144 0-0.28092-0.001809-0.42236 0.14077l-1.4143 2.1392c-0.99005-0.42775-2.2643-0.71216-3.5372-0.71216-1.2729 0-2.403 0.2844-3.5345 0.71216l-1.4144-2.1392c-0.10608-0.10694-0.21347-0.21211-0.31955-0.19874zm1.3088 4.905c0.42431 0 0.85027 0.2844 0.85027 0.71215 0 0.57034-0.42596 0.85569-0.85027 0.85569-0.42431 0-0.84749-0.42794-0.84749-0.85569s0.42319-0.71215 0.84749-0.71215zm7.6386 0c0.56574 0 0.84749 0.2844 0.84749 0.71215 0 0.57034-0.42319 0.85569-0.84749 0.85569-0.42431 0-0.85027-0.42794-0.85027-0.85569s0.42597-0.71215 0.85027-0.71215zm-14.852 4.7035c-0.99005 0-1.8395 0.71329-1.8395 1.7114v7.7012c0 0.85551 0.84943 1.7114 1.8395 1.7114h0.14171c0.99005 0 1.8395-0.71329 1.8395-1.7114v-7.7012c0-0.85551-0.84943-1.7114-1.8395-1.7114zm22.179 0c-0.99005 0-1.8395 0.71329-1.8395 1.7114v7.7012c0 0.85551 0.84943 1.7114 1.8395 1.7114h0.14171c0.99005 0 1.8395-0.71329 1.8395-1.7114v-7.7012c0-0.85551-0.84943-1.7114-1.8395-1.7114zm-18.926 0.85569v12.12c0 0.85551 0.70744 1.5678 1.5561 1.5678h1.6978v4.1349c0 0.85551 0.70494 1.7114 1.695 1.7114h0.14171c0.84861 0 1.6978-0.71329 1.6978-1.7114v-4.1349h2.4035v4.1349c0 0.85551 0.70772 1.7114 1.6978 1.7114h0.14171c0.84861 0 1.6978-0.71329 1.6978-1.7114v-4.2757h1.5866c0.84861 0 1.5561-0.71234 1.5561-1.5678v-0.0028c0-3.5485-0.01945-5.5838-0.01945-11.936z"
        fill="#ffffff"
        strokeWidth="7.6685"
      />
    </IconHelper>
  );
};

export default AndroidIcon;
