import { gql } from "@apollo/client";

const GET_ADVERTISER = gql`
  query advertiser($id: ID!) {
    advertiser(id: $id) {
      accountManager {
        id
        email
        firstName
        lastName
      }
      advertiserTier {
        id
        name
      }
      billingInfo
      cmsId
      createdAt
      domain
      enabled
      fairbidConsoleId
      maxBid
      id
      name
      offerTypes
      pocEmail
      pocName
      pocPhone
      totalBalance
      updatedAt
      trusted
      direct
      unlimitedBalance
      sendLowBalanceAlert
      sendLowBudgetAlert
      apiTokens {
        kind
        readValue
      }
    }
  }
`;

export default GET_ADVERTISER;
