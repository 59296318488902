import { CSSProperties } from "react";
import { Platform } from "types/product";
import AndroidIcon from "./AndroidIcon";
import IosIcon from "./IosIcon";
import QuestionMarkIcon from "./QuestionMarkIcon";
import SamsungIcon from "./SamsungIcon";
import WebIcon from "./WebIcon";

interface Props {
  platform: Platform;
  size: number;
  style?: CSSProperties;
}

export default function PlatformIcon(props: Props) {
  const { platform, size, ...otherProps } = props;

  switch (platform) {
    case "android":
      return <AndroidIcon width={size} {...otherProps} outlined={true} />;
    case "web":
      return <WebIcon width={size} {...otherProps} outlined={true} />;
    case "ios":
      return <IosIcon width={size} {...otherProps} outlined={true} />;
    case "samsung":
      return <SamsungIcon width={size} {...otherProps} outlined={true} />;
    default:
      return <QuestionMarkIcon width={size} {...otherProps} />;
  }
}
