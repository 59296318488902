import {
  ClickAwayListener,
  Fade,
  InputAdornment,
  ListItem,
  TextField,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { SearchSharp } from "@material-ui/icons";
import CaretDownIcon from "assets/icons/CaretDownIcon";
import HtmlTooltip from "components/UI/HtmlTooltip/HtmlTooltip";
import { useGetAllAdvertisers } from "graphql/advertiser/hooks";
import { Advertiser } from "graphql/advertiser/types";
import useViewByRole from "hooks/useViewByRole";
import deburr from "lodash/deburr";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { usePageStore } from "store/page";
import { useUserStore } from "store/user";
import { colors } from "theme";
import { USER_ROLES } from "utils/constants";
import styles from "./AdvertiserDropdownStyles";

const useStyles = makeStyles(styles);

const AdvertiserDropdown = observer(() => {
  const [open, setOpen] = useState(false);

  const navMenuOpen = usePageStore((state) => state.navMenuOpen);

  const classes = useStyles({ navMenuOpen });

  const [filter, setFilter] = useState<string>("");
  const [advertisers, setAdvertisers] = useState<Advertiser[]>([]);

  const userHasRole = useViewByRole();

  const { data: availableAdvertisers } = useGetAllAdvertisers();

  const setSelectedAdvertiser = useUserStore(
    (state) => state.setSelectedAdvertiser,
  );

  const filterRef = useRef<HTMLInputElement>();
  useEffect(() => {
    if (open) {
      filterRef.current?.focus();
    }
  }, [open]);

  function onFilterChange(event) {
    const value = event.target.value;
    setFilter(value);
  }

  useEffect(() => {
    function filterAdvertisers() {
      const normalize = (text: string) => deburr(text.trim().toLowerCase());
      const filterByName = ({ name }) =>
        normalize(name).includes(normalize(filter));
      const filteredAdvertisers =
        availableAdvertisers?.advertisers?.filter(filterByName);

      if (userHasRole(USER_ROLES.ADMIN)) {
        // Only sort for admins
        // For the other roles backend already returns a sorted list
        return filteredAdvertisers.sort((a, b) => a.name.localeCompare(b.name));
      }
      return filteredAdvertisers;
    }

    setAdvertisers(filterAdvertisers());
  }, [availableAdvertisers, filter]);

  return (
    <>
      <HtmlTooltip
        title="Switch Advertiser"
        placement="bottom"
        fontSize={12}
        noShadow
        inverse
      >
        <div
          className={classes.root}
          onClick={(e) => {
            e.stopPropagation();
            setOpen((open) => !open);
          }}
        >
          <CaretDownIcon
            data-cy="advertiser-dropdown"
            height={7}
            color={colors.softBlack}
            style={{
              transform: open ? "rotate(180deg)" : null,
            }}
          />
        </div>
      </HtmlTooltip>

      <Fade in={open} timeout={{ enter: 300, exit: 250 }}>
        <div className={classes.backdrop}>
          <ClickAwayListener
            mouseEvent={open ? "onClick" : false}
            touchEvent={open ? "onTouchEnd" : false}
            onClickAway={() => open && setOpen(false)}
          >
            <div className={classes.selectArea}>
              <ListItem className={classes.searchInput} dense>
                <TextField
                  inputRef={filterRef}
                  fullWidth
                  onChange={onFilterChange}
                  InputProps={{
                    placeholder: "Search by Advertiser Name",
                    value: filter,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchSharp />
                      </InputAdornment>
                    ),
                  }}
                />
              </ListItem>

              {advertisers.map((advertiser) => (
                <ListItem
                  data-cy={`advertiser-${advertiser.id}`}
                  key={advertiser.id}
                  dense
                  onClick={() => {
                    setSelectedAdvertiser(advertiser);

                    setOpen(false);
                  }}
                  className={classes.listItemRoot}
                  style={{ cursor: "pointer" }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ fontSize: 14 }}>
                      {advertiser.name}
                      {advertiser.cmsId ? ` (${advertiser.cmsId})` : null}
                    </div>
                  </div>
                </ListItem>
              ))}
            </div>
          </ClickAwayListener>
        </div>
      </Fade>
    </>
  );
});

export default withStyles(styles, { withTheme: true })(AdvertiserDropdown);
