import IconHelper from "./IconHelper";

const UserIcon = (props) => (
  <IconHelper {...props} defaultWidth={28} defaultHeight={28}>
    <path
      fill="#fff"
      d="M23.5,20.5c0-0.1,0-0.1,0-0.1c0-0.9-0.1-3-2.1-3.7c-2.1-0.5-3.9-1.7-3.9-1.8c-0.3-0.2-0.7-0.1-0.9,0.2c-0.2,0.3-0.1,0.7,0.2,0.9c0.1,0.1,1.9,1.3,4.2,1.9c1.1,0.4,1.2,1.5,1.2,2.6c0,0,0,0.1,0,0.1c0,0.4,0,1.1-0.1,1.4c-0.7,0.4-3.7,1.9-8.2,1.9c-4.4,0-7.4-1.5-8.2-1.9c-0.1-0.4-0.1-1-0.1-1.4c0,0,0-0.1,0-0.1c0-1,0.2-2.2,1.2-2.6c2.3-0.6,4.1-1.9,4.2-1.9c0.3-0.2,0.4-0.6,0.2-0.9c-0.2-0.3-0.6-0.3-0.9-0.2c0,0-1.7,1.2-3.8,1.7c-2.1,0.7-2.1,2.8-2.1,3.7c0,0,0,0.1,0,0.1c0,0.3,0,1.5,0.2,2.1c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,3.5,2.2,9,2.2c5.6,0,8.9-2.1,9-2.2c0.1-0.1,0.2-0.2,0.2-0.3C23.5,22,23.5,20.8,23.5,20.5z M13.9,14.9h0.1c1.4,0,2.5-0.5,3.3-1.4c1.8-2,1.5-5.4,1.5-5.7c-0.1-2.5-1.3-3.6-2.2-4.2C15.8,3.1,15,2.9,14,2.9H14c-0.5,0-1.5,0.1-2.5,0.6c-1,0.5-2.2,1.7-2.3,4.2c0,0.3-0.3,3.7,1.5,5.7C11.5,14.4,12.6,14.8,13.9,14.9zM10.4,7.8C10.4,7.8,10.5,7.8,10.4,7.8C10.6,4.5,13,4.1,14,4.1H14c1.2,0,3.4,0.5,3.5,3.7c0,0,0,0,0,0c0,0,0.3,3.2-1.1,4.8c-0.6,0.7-1.4,1-2.4,1h0c-1,0-1.8-0.3-2.4-1C10.1,11,10.4,7.9,10.4,7.8z"
    />
  </IconHelper>
);

export default UserIcon;
