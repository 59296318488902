import { List } from "@material-ui/core";
import { usePageStore } from "store/page";
import NavItem from "../NavItem/NavItem";

interface Props {
  entries: any[];
  navMenuOpen: boolean;
  subMenuOpened: boolean;
  setSubMenuOpened: (e: boolean) => void;
}

const NavList = (props: Props) => {
  const { entries, ...rest } = props;

  const currentPageId = usePageStore((state) => state.currentPageId);

  const parentId = entries.reduce((prev, cur) => {
    if (cur.id === currentPageId) {
      return cur.getMenuItemPageId ? cur.getMenuItemPageId() : cur.id;
    } else {
      if (cur.subRoutes) {
        const inner = cur.subRoutes.reduce((prevInner, curInner) => {
          return curInner.id === currentPageId ? true : prevInner;
        }, false);
        return inner
          ? cur.getMenuItemPageId
            ? cur.getMenuItemPageId()
            : cur.id
          : prev;
      }
      return prev;
    }
  }, -1);

  return (
    <List>
      {entries
        .filter((item) => (item.visible ? item.visible() : true))
        .map((item) => {
          const menuPageId = item.getMenuItemPageId && item.getMenuItemPageId();
          return menuPageId ? null : (
            <NavItem
              {...rest}
              selected={item.id === parentId}
              item={item}
              key={item.id}
            />
          );
        })}
    </List>
  );
};

export default NavList;
