import { Advertiser } from "graphql/advertiser/types";
import { Timestamp } from "types/misc";
import create from "zustand";

interface IUser {
  advertiser_group_id: number | null;
  advertiser_id: number | null;
  created_at: Timestamp;
  email: string;
  enabled: boolean;
  first_name: string;
  id: number;
  jti: string;
  last_name: string;
  log_data: null;
  phone: string;
  provider: null;
  roles: string[];
  tcs_agreed_at: null;
  uid: null;
  updated_at: Timestamp;
}

interface Store {
  token: string;
  user: IUser;
  isAuthenticated: boolean;
  selectedAdvertiser: Partial<Advertiser>;
  setToken: (token: string) => void;
  setUser: (user: IUser) => void;
  setIsAuthenticated: (isAuth: boolean) => void;
  setSelectedAdvertiser: (adv: any) => void;
}

export const useUserStore = create<Store>((set) => ({
  token: localStorage.token ?? "",
  user: (localStorage.user && JSON.parse(localStorage.user)) ?? undefined,
  isAuthenticated: Boolean(
    localStorage.token && localStorage.user && JSON.parse(localStorage.user),
  ),
  selectedAdvertiser: { id: localStorage.adv_id },
  setToken: (token) => {
    set(() => ({ token }));
  },
  setUser: (user: IUser) => {
    set(() => ({ user }));
  },
  setIsAuthenticated: (isAuthenticated: boolean) => {
    set(() => ({ isAuthenticated }));
  },
  setSelectedAdvertiser: (selectedAdvertiser: Advertiser) => {
    if (selectedAdvertiser?.id) localStorage.adv_id = selectedAdvertiser.id;
    set(() => ({ selectedAdvertiser }));
  },
}));
