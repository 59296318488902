import { gql } from "@apollo/client";

const DELETE_PRODUCT = gql`
  mutation productDestroy($id: ID!) {
    productDestroy(input: { id: $id }) {
      product {
        id
        advertiserId
      }
      errors
    }
  }
`;

export default DELETE_PRODUCT;
