import { createStyles } from "@material-ui/styles";
import { colors } from "theme";
import { NAV_MENU_WIDTH } from "utils/constants";

const styles = (theme) =>
  createStyles<any, { navMenuOpen: boolean }>({
    drawer: {
      width: NAV_MENU_WIDTH.closed,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerPaper: {
      backgroundImage: `linear-gradient(225deg, ${colors.red[500]} 0%, ${colors.plum} 100%)`,
      overflowX: "hidden",
    },
    drawerOpen: {
      width: NAV_MENU_WIDTH.open,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
      }),
    },
    drawerClose: {
      width: NAV_MENU_WIDTH.closed,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      paddingLeft: 13,
      ...theme.mixins.toolbar,
    },
    expandCollapseMenu: {
      position: "absolute",
      bottom: 0,
      left: 7,
    },
    accountBalance: {
      position: "absolute",
      bottom: 40,
      color: "white",
      display: "flex",
      flexDirection: "row",
      left: 19,
    },
    dollar: {
      fontSize: 25,
      marginRight: 16,
    },
    balanceDetails: (props) => ({
      visibility: props.navMenuOpen ? null : "hidden",
    }),
    balanceLabel: {
      fontSize: 12,
    },
    balanceValue: {
      fontWeight: 600,
    },
  });

export default styles;
