import {
  CircularProgress,
  ClickAwayListener,
  Fade,
  InputAdornment,
  ListItem,
  ListItemText,
  TextField,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { SearchSharp } from "@material-ui/icons";
import clsx from "clsx";
import { useGetAllOfferCampaigns } from "graphql/offer-campaign/hooks";
import useShowToast from "hooks/useShowToast";
import deburr from "lodash/deburr";
import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { colors } from "theme";
import { useDebouncedCallback } from "use-debounce";
import { closeDropdownById } from "utils/closeDropdownById";
import CaretDownIcon from "../../assets/icons/CaretDownIcon";
import HtmlTooltip from "../../components/UI/HtmlTooltip/HtmlTooltip";
import { PAGE_PATHS } from "../../routes/routes";
import { DEBOUNCE_DELAY_LOCAL } from "../../utils/constants";
import styles from "./ProductOfferDropdownStyles";

const OfferDropdown = (props) => {
  const { classes, allItems, shownId, productId } = props;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    // reset state
    setRedirect(null);
    setOpen(false);
    setDataLoaded(false);
  }, [shownId]);

  const [filter, setFilter] = useState(""); // the "real" filter value
  const [filterText, setFilterText] = useState(""); // filter input text, will be set as filter after debounce delay

  const [allListItems, setAllListItems] = useState(allItems ? allItems : []);
  const [listItems, setListItems] = useState(allItems ? allItems : []);

  const [dataLoaded, setDataLoaded] = useState(false);
  const [redirect, setRedirect] = useState(null);

  /**
   * Hook for debounced search field.
   */
  const debouncedFilter = useDebouncedCallback((value) => {
    setFilter(value);
  }, DEBOUNCE_DELAY_LOCAL);

  /**
   * Initiates debounced filter change.
   * @param {string} value
   */
  const onFilterChange = (value) => {
    setFilterText(value);
    debouncedFilter(value);
  };

  useEffect(() => {
    const compareFilter = deburr(filter.trim()).toLowerCase();
    const filteredItems = allListItems.filter((item) =>
      deburr(item.name.trim().toLowerCase()).includes(compareFilter),
    );
    setListItems(filteredItems);
  }, [filter]);

  const { showError } = useShowToast();
  const { data, loading, error } = useGetAllOfferCampaigns({ productId });

  useEffect(() => {
    if (error) {
      showError(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      if (!dataLoaded) setDataLoaded(true);

      const result = data?.productOfferCampaigns ?? [];

      const items = result.filter((item) => {
        let result = item.id + "" !== shownId + "";
        return result;
      });
      setListItems(items);
      setAllListItems(items);
    }
  }, [data]);

  const redirectTo = (id) => {
    setRedirect(
      props.edit
        ? `${PAGE_PATHS.editOffer.replace(":offerId", id)}`
        : `${PAGE_PATHS.offerDetails.replace(":offerId", id)}`,
    );
  };

  if (redirect) return <Redirect to={redirect} />;

  return (
    <>
      <HtmlTooltip
        title={"Switch Campaign"}
        placement="bottom"
        fontSize={12}
        noShadow
        inverse
      >
        <div
          id="offer-dropdown"
          className={classes.root}
          onClick={(e) => {
            closeDropdownById("product-dropdown");
            e.stopPropagation();
            setOpen((open) => !open);
          }}
        >
          <CaretDownIcon
            height={7}
            color={colors.softBlack}
            style={{
              transform: open ? "rotate(180deg)" : null,
            }}
          ></CaretDownIcon>
        </div>
      </HtmlTooltip>

      <Fade in={open} timeout={500}>
        <div className={classes.backdrop}>
          <ClickAwayListener
            mouseEvent={open ? "onClick" : false}
            touchEvent={open ? "onTouchEnd" : false}
            onClickAway={() => open && setOpen(false)}
          >
            <div className={classes.selectArea}>
              {loading ? (
                <CircularProgress
                  size={40}
                  className={classes.loadingIndicator}
                />
              ) : null}

              <ListItem className={classes.searchInput} dense>
                <TextField
                  fullWidth
                  onChange={(e) => onFilterChange(e.target.value)}
                  InputProps={{
                    placeholder: "Search by Campaign Name",
                    value: filterText,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchSharp />
                      </InputAdornment>
                    ),
                  }}
                />
              </ListItem>

              <ListItem dense>
                <ListItemText
                  classes={{ primary: classes.itemCount }}
                  primary={`Campaigns (${listItems.length} of ${allListItems.length})`}
                />
              </ListItem>

              {listItems.map((item) => {
                const typeText = item.costModel
                  ? item.costModel.toUpperCase()
                  : null;

                const textElement = (
                  <div className={classes.textWrapper}>
                    <div className={classes.entryText}>{item.name}</div>
                    {typeText ? (
                      <div className={classes.typeText}>{typeText}</div>
                    ) : null}
                  </div>
                );

                return (
                  <ListItem
                    key={item.id}
                    dense
                    onClick={() => redirectTo(item.id)}
                    className={clsx({
                      [classes.listItemRoot]: true,
                      [classes.listItemRootDisabled]: !item.enabled,
                    })}
                    title={item.enabled ? null : "Disabled"}
                  >
                    <div className={classes.entryWrapper}>{textElement}</div>
                  </ListItem>
                );
              })}
            </div>
          </ClickAwayListener>
        </div>
      </Fade>
    </>
  );
};

OfferDropdown.defaultProps = {
  allItems: false,
};

export default withStyles(styles, { withTheme: true })(OfferDropdown);
