import { useMutation, useQuery } from "@apollo/client";
import GET_ALL_OFFER_CAMPAIGNS from "graphql/offer-campaign/get-all";
import { useDateRangeStore } from "store/date-range";
import { ID, Timestamp } from "types/misc";
import { Product } from "types/product";
import DELETE_PRODUCT from "./delete";
import GET_PRODUCT from "./get";
import GET_ALL_PRODUCTS from "./getAll";
import POST_PRODUCT from "./post";
import POST_WEB_PRODUCT from "./postWeb";
import PUT_PRODUCT from "./put";

interface GetAllData {
  advertiserProducts: Product[];
}

interface GetAllVariables {
  advertiserId: ID;
  startDate?: Timestamp;
  endDate?: Timestamp;
}

export function useGetAllProducts(variables: GetAllVariables) {
  return useQuery<GetAllData, GetAllVariables>(GET_ALL_PRODUCTS, {
    variables,
    skip: !variables.advertiserId,
    fetchPolicy: (window as any).Cypress ? "cache-first" : "cache-and-network",
  });
}

interface GetData {
  product: Product;
}

interface GetVariables {
  id: string;
}

export function useGetProduct(variables: GetVariables) {
  return useQuery<GetData, GetVariables>(GET_PRODUCT, {
    variables,
    skip: !variables.id,
  });
}

interface PostVariables {}

export function usePostProduct() {
  const startDate = useDateRangeStore((state) => state.startDateSeconds);
  const endDate = useDateRangeStore((state) => state.endDateSeconds);

  const [mutate, response] = useMutation<any, PostVariables>(POST_PRODUCT, {
    refetchQueries: ({ data }) => {
      const { product, errors } = data.productCreate ?? {};
      const advertiserId = product.advertiserId;
      return errors.length
        ? []
        : [
            {
              query: GET_ALL_PRODUCTS,
              variables: {
                advertiserId,
                startDate,
                endDate,
              },
            },
          ];
    },
  });
  return { mutate, ...response };
}

interface PostWebData {
  webProductCreate: {
    product: Product;
    errors: string[];
  };
}
interface PostWebVariables {
  advertiserId: ID;
  name: string;
  url: string;
  icon: any; // TODO: Get type
}

export function usePostWebProduct() {
  const [mutate, response] = useMutation<PostWebData, PostWebVariables>(
    POST_WEB_PRODUCT,
    {
      refetchQueries: ({ data }) => {
        const { product, errors } = data.webProductCreate ?? {};
        const advertiserId = product.advertiserId;
        return errors.length
          ? []
          : [{ query: GET_ALL_PRODUCTS, variables: { advertiserId } }];
      },
    },
  );
  return { mutate, ...response };
}

interface PutData {
  productUpdate: {
    product: Product;
    errors: string[];
  };
}
type PutVariables = Partial<Product>;

export function usePutProduct() {
  const startDate = useDateRangeStore((state) => state.startDateSeconds);
  const endDate = useDateRangeStore((state) => state.endDateSeconds);

  const [mutate, response] = useMutation<PutData, PutVariables>(PUT_PRODUCT, {
    refetchQueries: ({ data: { productUpdate } }) => {
      const { product, errors } = productUpdate;

      return errors.length
        ? []
        : [
            {
              query: GET_PRODUCT,
              variables: { id: product.id },
            },
            {
              query: GET_ALL_PRODUCTS,
              variables: {
                advertiserId: product.advertiserId,
                startDate,
                endDate,
              },
            },
            {
              query: GET_ALL_OFFER_CAMPAIGNS,
              variables: {
                productId: product.id,
                startDate,
                endDate,
              },
            },
          ];
    },
  });
  return { mutate, ...response };
}

interface DeleteData {
  productDestroy: {
    product: Product;
    errors: string[];
  };
}
interface DeleteVariables {
  id: ID;
}

// TODO Check if there is a better way to pass advertiser id
// since delete product doesn't return the deleted product
export function useDeleteProduct(advertiserId: ID) {
  const [mutate, response] = useMutation<DeleteData, DeleteVariables>(
    DELETE_PRODUCT,
    {
      refetchQueries: ({ data: { productDestroy } }) => {
        const { errors } = productDestroy;
        return errors.length
          ? []
          : [
              {
                query: GET_ALL_PRODUCTS,
                variables: { advertiserId },
              },
            ];
      },
    },
  );
  return { mutate, ...response };
}
