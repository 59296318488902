import { createTheme } from "@material-ui/core/styles";

export const colors = {
  // Base
  softBlack: "#231F20",
  black: "#000",
  white: "#fff",

  // Dark Blue
  plum: "#251546",
  charcoal: {
    soft: "#8f93b3",
    light: "#dddfe8",
  },

  // Turbine Red
  red: {
    0: "#f9dde3",
    50: "#f6cdd6",
    100: "#f1acbb",
    200: "#ec8ca0",
    300: "#e76b85",
    400: "#e24b6a",
    500: "#da1a42",
    600: "#c4173b",
    700: "#ae1535",
    800: "#99122e",
    900: "#831028",
  },

  // Ignite Orange
  orange: {
    500: "#f05532",
  },

  //  Monetization Purple
  purple: {
    0: "#f1e3f1",
    50: "#ead3ea",
    100: "#deb9de",
    200: "#d1a0d1",
    300: "#c484c4",
    400: "#b059b0",
    500: "#a441a4",
    600: "#943b94",
    700: "#833483",
    800: "#732e73",
    900: "#622762",
  },

  // Golden Yellow
  yellow: {
    0: "#fff5dc",
    50: "#fff0ca",
    100: "#ffe6a5",
    200: "#ffde89",
    300: "#ffd25d",
    400: "#ffc839",
    500: "#ffbe14",
    600: "#b0610c",
    700: "#94510a",
    800: "#80470a",
    900: "#6d3c09",
  },

  // Grass Blade
  green: {
    0: "#ebf3e1",
    50: "#e0ecd1",
    100: "#d3e4be",
    200: "#c6dcaa",
    300: "#b9d597",
    400: "#abcc82",
    500: "#99c167",
    600: "#5e7f34",
    700: "#55722f",
    800: "#425924",
    900: "#384c1f",
  },

  // Grays
  gray: {
    50: "#fafafa",
    100: "#f5f5f5",
    200: "#eaeaea",
    300: "#e1e1e1",
    400: "#cacaca",
    500: "#b3b3b3",
    600: "#8e8e8e",
    700: "#6e6e6e",
    800: "#4b4b4b",
    900: "#2c2c2c",
  },
};

const theme = createTheme({
  palette: {
    background: { default: colors.gray[100] },
    primary: {
      main: colors.red[500],
      contrastText: colors.white,
    },
    secondary: { main: colors.plum, contrastText: colors.white },
    success: { main: colors.green[500] },
    warning: { main: colors.yellow[500] },
    error: { main: colors.red[500] },
  },

  typography: {
    fontFamily: "Inter, sans-serif",
  },

  props: {
    MuiCheckbox: {
      color: "primary",
    },
  },

  overrides: {
    MuiButton: {
      root: {
        borderRadius: 5,
        border: 0,
        fontWeight: 600,
        textTransform: "capitalize",
        padding: "8px 16px",
        letterSpacing: "1.25px",
      },
      sizeSmall: {
        padding: "6px 10px",
        fontSize: 12,
        lineHeight: "12px",
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "#E8E8E8",
        fontWeight: 400,
        height: 26,
      },
    },
    MuiAccordion: {
      rounded: {
        borderRadius: 5,
      },
      root: {
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.02)",
      },
    },
    MuiAccordionSummary: {
      content: {
        color: colors.softBlack,
        fontWeight: 400,
        fontSize: 16,
      },
      root: {
        "&.Mui-expanded": {
          borderBottom: `1px solid ${colors.gray[100]}`,
        },
      },
    },
    MuiFormControl: {
      root: {
        marginTop: 8,
        marginBottom: 4,
      },
    },
    MuiFormControlLabel: {
      root: {
        height: 32,
        overflowY: "visible",
      },
      label: {
        fontSize: "14px",
        color: colors.softBlack,
      },
    },
    MuiFormHelperText: {
      root: {
        fontWeight: 300,
        minHeight: 19.2,
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: 14,
        color: colors.softBlack,
        "&$focused": {
          color: colors.green[500],
        },
        "&$disabled": {
          color: colors.softBlack,
        },
      },
    },
    MuiSwitch: {
      colorPrimary: {
        "&$checked": {
          color: colors.green[500],
        },
        "&$checked + $track": {
          backgroundColor: colors.green[500],
        },
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        "&$checked": { color: colors.green[500] },
      },
    },
    MuiInput: {
      input: {
        fontSize: 14,
        height: 19,
        paddingTop: 3,
      },
      inputMultiline: {
        height: "auto",
      },
      underline: {
        "&:before": {
          borderBottomColor: colors.gray[300],
        },
        "&:after": {
          borderBottomColor: colors.gray[300],
        },
        "&$focused:after": {
          borderBottomColor: colors.green[500],
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottomColor: colors.gray[300],
        },
        "&$error:after": {
          borderBottomColor: colors.red[500],
        },
        "&$error:hover:not($disabled):not($focused):after": {
          borderBottomColor: colors.red[500],
        },
        "&:hover:not($disabled):before": {
          borderBottomColor: colors.gray[300],
        },
      },
    },
    MuiInputBase: {
      root: {
        marginTop: 0,
        fontWeight: 400,
      },
    },
    MuiInputAdornment: {
      positionStart: {
        marginRight: 0,
      },
    },
    MuiInputLabel: {
      shrink: {
        fontSize: 16,
      },
    },
    MuiLink: {
      root: {
        cursor: "pointer",
      },
    },
    MuiListItemIcon: {
      root: {
        color: colors.white,
      },
    },
    MuiListItemText: {
      primary: {
        // fontWeight: 400,
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: 14,
      },
      dense: {
        fontWeight: 400,
      },
    },
    MuiOutlinedInput: {
      root: {
        "&.Mui-error $notchedOutline": {
          borderWidth: 2,
        },
        "&:hover $notchedOutline": {
          borderColor: colors.gray[300],
          borderWidth: 2,
        },
      },
    },
    MuiSelect: {
      selectMenu: {
        height: 19,
      },
    },
    MuiSlider: {
      root: {
        height: 5,
      },
      markLabel: {
        fontSize: 12,
      },
      rail: {
        color: colors.gray[700],
        height: 5,
        borderRadius: 3,
      },
      thumb: {
        height: 14,
        width: 14,
        "&:focus,&:hover,&$active": {
          boxShadow: "inherit",
        },
      },
      track: {
        height: 5,
        borderRadius: 3,
      },
    },
    MuiSvgIcon: {
      colorPrimary: {
        color: colors.white,
      },
    },
    MuiTable: { root: { tableLayout: "fixed", width: "100%" } },
    MuiTableCell: {
      root: { wordBreak: "break-word", borderBottomWidth: 0.5 },
      body: {
        fontWeight: 400,
        padding: 12,

        "&:not($paddingNone):first-child": {
          paddingLeft: 24,
        },
        "&:last-child": {
          paddingRight: 24,
        },
      },
      head: {
        color: colors.softBlack,
        fontWeight: 600,
        paddingLeft: 12,
        paddingRight: 12,

        "&:not($paddingNone):first-child": {
          paddingLeft: 24,
        },
        "&:last-child": {
          paddingRight: 24,
        },
      },
      sizeSmall: { paddingLeft: 8, paddingRight: 8 },
      stickyHeader: {
        backgroundColor: "white",
      },
    },
    MuiTableRow: {
      root: {
        "&[disabled]": {
          "& > td": {
            opacity: 0.5,
            backgroundColor: colors.gray[100],
          },
        },
        "&:not([nohover]):hover > td": {
          backgroundColor: "rgba(245, 245, 245) !important",
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: colors.white,
        color: colors.softBlack,
      },
    },
    MuiTypography: {
      h3: {
        fontSize: "20px",
        fontWeight: 300,
      },
      h4: {
        fontSize: "18px",
        fontWeight: 300,
        lineHeight: "normal",
      },
      h5: {
        fontSize: "16px",
        fontWeight: 400,
      },
      h6: {
        fontSize: "14px",
        fontWeight: 300,
      },
    },
  },
});

export default theme;
