import { gql } from "@apollo/client";

const DUPLICATE_OFFER_CAMPAIGN = gql`
  mutation offerCampaignDuplicate($id: ID!) {
    offerCampaignDuplicate(input: { id: $id }) {
      offerCampaign {
        id
        name
        productId
        product {
          enabled
        }
        bid
        countryGroupBudgets
        capping {
          dailyBudget
          monthlyBudget
          lifetimeBudget
          dailyConversionCap
          # monthlyConversionCap
          lifetimeConversionCap
          dailyInstallCap
          # monthlyInstallCap
          lifetimeInstallCap
        }
        enabled
        enabledFromAdvertiser
        enabledAt
      }
      errors
    }
  }
`;

export default DUPLICATE_OFFER_CAMPAIGN;
