import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./assets/fonts/fonts.css";
import ApolloProvider from "./graphql/apollo";
import theme, { colors } from "./theme";

const useStyles = makeStyles({
  variantSuccess: {
    backgroundColor: colors.green[500],
    color: colors.white,
  },
  variantError: {
    backgroundColor: colors.red[50],
    color: colors.red[500],
    border: `1px solid ${colors.red[200]}`,
  },
  variantWarning: {
    backgroundColor: colors.yellow[500],
    color: colors.white,
  },
});

if (process.env.REACT_APP_ENVIRONMENT === "test") {
  const { worker } = require("./tests/mocks/server");
  worker.start();

  // @ts-expect-error
  window.msw = { worker };
}

const Providers = () => {
  const classes = useStyles();
  return (
    <ApolloProvider>
      <BrowserRouter>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider
            classes={classes}
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <DndProvider backend={HTML5Backend}>
              <App />
            </DndProvider>
          </SnackbarProvider>
        </MuiThemeProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
};

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<Providers />);
