import { Drawer, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import DigitalTurbineLogoIcon from "assets/icons/DigitalTurbineLogoIcon";
import DigitalTurbineTextLogoIcon from "assets/icons/DigitalTurbineTextLogoIcon";
import ExpandCollapseMenuIcon from "assets/icons/ExpandCollapseMenuIcon";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { Dispatch, SetStateAction, useState } from "react";
import { usePageStore } from "store/page";
import { useUserStore } from "store/user";
import { getCurrencyValue } from "utils/numberFormat";
import NavList from "../NavList/NavList";
import styles from "./styles";

const useStyles = makeStyles(styles);

interface Props {
  entries: any[];
  navMenuOpen: boolean;
  setNavMenuOpen: Dispatch<SetStateAction<boolean>>;
  subMenuOpened: boolean;
  setSubMenuOpened: Dispatch<SetStateAction<boolean>>;
}

const SideMenu = observer((props: Props) => {
  const {
    entries,
    navMenuOpen,
    setNavMenuOpen,
    subMenuOpened,
    setSubMenuOpened,
  } = props;

  const classes = useStyles({ navMenuOpen });

  const [subMenuWasOpened, setSubMenuWasOpened] = useState(false);

  const selectedAdvertiser = useUserStore((state) => state.selectedAdvertiser);
  const storeNavMenuOpen = usePageStore((state) => state.setNavMenuOpen);

  const handleMenuState = () => {
    setNavMenuOpen((prev) => {
      if (prev) {
        setSubMenuWasOpened(subMenuOpened);
        subMenuOpened && setSubMenuOpened(false);
        storeNavMenuOpen(false);
        return false;
      } else {
        subMenuWasOpened && setSubMenuOpened(subMenuWasOpened);
        storeNavMenuOpen(true);
        return true;
      }
    });
  };

  return (
    <nav className={classes.drawer}>
      <Drawer
        variant="permanent"
        className={clsx({
          [classes.drawerOpen]: navMenuOpen,
          [classes.drawerClose]: !navMenuOpen,
        })}
        classes={{
          paper: clsx(
            {
              [classes.drawerOpen]: navMenuOpen,
              [classes.drawerClose]: !navMenuOpen,
            },
            classes.drawerPaper,
          ),
        }}
        open={navMenuOpen}
      >
        <div className={classes.toolbar}>
          {navMenuOpen ? (
            <DigitalTurbineTextLogoIcon height={32} color="white" />
          ) : (
            <DigitalTurbineLogoIcon height={31} width={31} color="white" />
          )}
        </div>
        <div>
          <NavList
            entries={entries}
            subMenuOpened={subMenuOpened}
            setSubMenuOpened={setSubMenuOpened}
            navMenuOpen={navMenuOpen}
          />
          <div className={classes.accountBalance}>
            <div className={classes.dollar}>$</div>
            <div className={classes.balanceDetails}>
              <div className={classes.balanceLabel}>Account balance</div>
              <div className={classes.balanceValue}>
                {selectedAdvertiser?.unlimitedBalance
                  ? "Unlimited"
                  : getCurrencyValue(selectedAdvertiser?.totalBalance, {
                      noTooltip: true,
                    })}
              </div>
            </div>
          </div>
          <div className={classes.expandCollapseMenu}>
            <IconButton onClick={handleMenuState}>
              <ExpandCollapseMenuIcon
                expand={!navMenuOpen}
              ></ExpandCollapseMenuIcon>
            </IconButton>
          </div>
        </div>
      </Drawer>
    </nav>
  );
});

export default withStyles(styles, { withTheme: true })(SideMenu);
