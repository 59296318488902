import { gql } from "@apollo/client";

const POST_WEB_PRODUCT = gql`
  mutation webProductCreate(
    $advertiserId: ID!
    $name: String!
    $icon: IconInput!
  ) {
    webProductCreate(
      input: {
        attributes: { advertiserId: $advertiserId, name: $name, icon: $icon }
      }
    ) {
      product {
        id
        name
        iconUrl
        advertiserId
      }
      errors
    }
  }
`;

export default POST_WEB_PRODUCT;
