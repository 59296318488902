import { useMutation, useQuery } from "@apollo/client";
import { cache } from "graphql/apollo";
import GET_ALL_PRODUCTS from "graphql/product/getAll";
import useShowToast from "hooks/useShowToast";
import { useDateRangeStore } from "store/date-range";
import { useUserStore } from "store/user";
import { ID, Timestamp } from "types/misc";
import { OfferCampaign } from "types/offer-campaign";
import DELETE_OFFER_CAMPAIGN from "./delete";
import DUPLICATE_OFFER_CAMPAIGN from "./duplicate";
import GET_OFFER_CAMPAIGN from "./get";
import GET_ALL_OFFER_CAMPAIGNS from "./get-all";
import POST_OFFER_CAMPAIGN from "./post";
import PUT_OFFER_CAMPAIGN from "./put";

interface GetAllData {
  productOfferCampaigns: OfferCampaign[];
}

interface GetAllVariables {
  productId: ID;
  startDate?: Timestamp;
  endDate?: Timestamp;
}

export function useGetAllOfferCampaigns(variables: GetAllVariables) {
  return useQuery<GetAllData, GetAllVariables>(GET_ALL_OFFER_CAMPAIGNS, {
    variables,
    fetchPolicy: (window as any).Cypress ? "cache-first" : "cache-and-network",
  });
}

interface GetData {
  offerCampaign: OfferCampaign;
}

interface GetVariables {
  id: ID;
}

export function useGetOfferCampaign(variables: GetVariables) {
  return useQuery<GetData, GetVariables>(GET_OFFER_CAMPAIGN, {
    variables,
    skip: !variables.id,
    fetchPolicy: "cache-and-network",
  });
}

type PostVariables = OfferCampaign;
type PostData = {
  offerCampaignCreate: { offerCampaign: OfferCampaign; errors: string[] };
};

export function usePostOfferCampaign() {
  const advertiserId = useUserStore((state) => state.selectedAdvertiser.id);
  const { showError } = useShowToast();

  const startDate = useDateRangeStore((state) => state.startDateSeconds);
  const endDate = useDateRangeStore((state) => state.endDateSeconds);

  const [mutate, response] = useMutation<PostData, PostVariables>(
    POST_OFFER_CAMPAIGN,
    {
      onError: (error) => showError(error.message),
      refetchQueries: ({ data: { offerCampaignCreate } }) => {
        const { offerCampaign, errors } = offerCampaignCreate;
        const productId = offerCampaign?.productId;
        return errors.length
          ? []
          : [
              {
                query: GET_ALL_OFFER_CAMPAIGNS,
                variables: {
                  productId,
                  startDate,
                  endDate,
                },
              },
              {
                query: GET_ALL_PRODUCTS,
                variables: {
                  advertiserId,
                  startDate,
                  endDate,
                },
              },
            ];
      },
    },
  );
  return { mutate, ...response };
}

interface PutData {
  offerCampaignUpdate: {
    offerCampaign: OfferCampaign;
    errors: string[];
  };
}

type PutVariables = Partial<OfferCampaign>;

export function usePutOfferCampaign() {
  const { showError } = useShowToast();
  const startDate = useDateRangeStore((state) => state.startDateSeconds);
  const endDate = useDateRangeStore((state) => state.endDateSeconds);

  const [mutate, response] = useMutation<PutData, PutVariables>(
    PUT_OFFER_CAMPAIGN,
    {
      onError: (error) => showError(error.message),
      refetchQueries: ({ data: { offerCampaignUpdate } }) => {
        const { offerCampaign, errors } = offerCampaignUpdate;
        return errors.length
          ? []
          : [
              {
                query: GET_ALL_OFFER_CAMPAIGNS,
                variables: {
                  productId: offerCampaign.productId,
                  startDate,
                  endDate,
                },
              },
            ];
      },
      onCompleted(data) {
        // (over)write cached campaign
        cache.writeQuery({
          query: GET_OFFER_CAMPAIGN,
          variables: { id: data.offerCampaignUpdate.offerCampaign.id },
          data: data.offerCampaignUpdate,
        });
      },
    },
  );
  return { mutate, ...response };
}

interface DuplicateData {
  offerCampaignDuplicate: {
    offerCampaign: OfferCampaign;
    errors: string[];
  };
}

type DuplicateVariables = Partial<OfferCampaign>;

export function useDuplicateOfferCampaign() {
  const { showError } = useShowToast();

  const [mutate, response] = useMutation<DuplicateData, DuplicateVariables>(
    DUPLICATE_OFFER_CAMPAIGN,
    {
      onError: (error) => showError(error.message),
    },
  );
  return { mutate, ...response };
}

interface DeleteData {
  offerCampaignDestroy: {
    offerCampaign: OfferCampaign;
    errors: string[];
  };
}
interface DeleteVariables {
  id: ID;
}

// TODO Check if there is a better way to pass product id
// since delete offer campaign doesn't return the deleted campaign

export function useDeleteOfferCampaign(productId: ID) {
  const { showError } = useShowToast();
  const startDate = useDateRangeStore((state) => state.startDateSeconds);
  const endDate = useDateRangeStore((state) => state.endDateSeconds);

  const [mutate, response] = useMutation<DeleteData, DeleteVariables>(
    DELETE_OFFER_CAMPAIGN,
    {
      onError: (error) => showError(error.message),
      refetchQueries: ({ data: { offerCampaignDestroy } }) => {
        const { errors } = offerCampaignDestroy;
        return errors.length
          ? []
          : [
              {
                query: GET_ALL_OFFER_CAMPAIGNS,
                variables: { productId, startDate, endDate },
              },
            ];
      },
    },
  );
  return { mutate, ...response };
}
