export const DATA_PATHS = {
  LOGIN_REST_POST: "/users/sign_in",
  LOGOUT_REST_DELETE: "/users/sign_out",
  RESET_PASSWORD_REST_PUT_OR_POST: "/users/password",
  INVITATION_PASSWORD_REST_PATCH: "/users/invitation",
  REGISTER_REST_POST: "/users",
  GRAPHQL: "/graphql",
  OKTA_AUTH_GET: "/users/auth/okta",
  OKTA_CALLBACK_GET: "/users/auth/okta/callback",
};
