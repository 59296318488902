import { gql } from "@apollo/client";

const PUT_ADVERTISER = gql`
  mutation advertiserUpdate(
    $id: ID!
    $accountManagerId: ID
    $enabled: Boolean
    $fairbidConsoleId: Int
    $maxBid: Int
    $name: String
    $offerTypes: [String!]
    $pocName: String
    $pocEmail: String
    $pocPhone: String
    $billingInfo: JSON
    $trusted: Boolean
    $direct: Boolean
    $domain: String
    $unlimitedBalance: Boolean
    $sendLowBalanceAlert: Boolean
    $sendLowBudgetAlert: Boolean
    $advertiserTierId: ID
  ) {
    advertiserUpdate(
      input: {
        advertiserId: $id
        attributes: {
          name: $name
          offerTypes: $offerTypes
          pocName: $pocName
          pocEmail: $pocEmail
          pocPhone: $pocPhone
          enabled: $enabled
          fairbidConsoleId: $fairbidConsoleId
          maxBid: $maxBid
          billingInfo: $billingInfo
          accountManagerId: $accountManagerId
          trusted: $trusted
          direct: $direct
          domain: $domain
          unlimitedBalance: $unlimitedBalance
          sendLowBalanceAlert: $sendLowBalanceAlert
          sendLowBudgetAlert: $sendLowBudgetAlert
          advertiserTierId: $advertiserTierId
        }
      }
    ) {
      errors
      advertiser {
        accountManager {
          id
          email
          firstName
          lastName
        }
        advertiserTier {
          id
          name
        }
        billingInfo
        cmsId
        createdAt
        enabled
        fairbidConsoleId
        maxBid
        id
        name
        offerTypes
        pocEmail
        pocName
        pocPhone
        totalBalance
        updatedAt
        trusted
        direct
        domain
        unlimitedBalance
        sendLowBalanceAlert
        sendLowBudgetAlert
      }
    }
  }
`;

export default PUT_ADVERTISER;
