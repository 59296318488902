import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Fragment } from "react";
import BreadcrumbDivider from "./BreadcrumbDivider";
import BreadcrumbItemWrapper from "./BreadcrumbItemWrapper";
import styles from "./BreadcrumbStyles";

export const DEFAULT_BREADCRUMB_HEIGHT = 65;

const Breadcrumb = (props) => {
  const { items, controls, classes, height, maxWidth, bottomBorder, ...rest } =
    props;

  const breadCrumbItems = items && (Array.isArray(items) ? items : [items]);
  const breadCrumbControls =
    controls && (Array.isArray(controls) ? controls : [controls]);

  const breadcrumbHeight = height || DEFAULT_BREADCRUMB_HEIGHT;

  const itemsCount = breadCrumbItems ? breadCrumbItems.length - 1 : 0;

  return (
    <div className={classes.root}>
      {breadCrumbItems &&
        breadCrumbItems.map((item, index) => {
          return (
            <Fragment key={index}>
              <BreadcrumbItemWrapper
                item={item}
                height={breadcrumbHeight}
                {...rest}
              />
              {index < itemsCount && (
                <BreadcrumbDivider height={breadcrumbHeight} />
              )}
            </Fragment>
          );
        })}
      {controls && (
        <div className={classes.controlsWrapper}>
          {breadCrumbControls.map((item, index) => {
            const idx = index + (breadCrumbItems ? breadCrumbItems.length : 0);
            return <Fragment key={idx}>{item}</Fragment>;
          })}
        </div>
      )}
    </div>
  );
};

Breadcrumb.defaultProps = {
  bottomBorder: true,
};

Breadcrumb.propTypes = {
  height: PropTypes.number,
  bottomBorder: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(Breadcrumb);
