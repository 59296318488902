import { withStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { colors } from "theme";
import Modal from "../../hoc/Modal";
import CloseIconButton from "../UI/Button/CloseIconButton";

const STORAGE_KEY = "_infoShown_multiReward";

const FullscreenInfo = () => {
  // const storageValue = localStorage.getItem(STORAGE_KEY) !== "true";
  const [modalVisible, setModalVisible] = useState(false);

  function handleClose() {
    localStorage.setItem(STORAGE_KEY, "true");
    setModalVisible(false);
  }

  if (!modalVisible) {
    return null;
  }

  return (
    <Modal height="290">
      <div style={{ position: "absolute", top: 0, right: 0, padding: 10 }}>
        <CloseIconButton
          onClick={handleClose}
          data-cy="after-login-popup-close-button"
        />
      </div>
      <div style={{ padding: "50px 50px 34px 50px", fontSize: "120%" }}>
        <div style={{ width: "100%", textAlign: "center" }}>
          <h3 style={{ marginTop: 0, textDecoration: "underline" }}>
            Just landed - new Multi-Reward feature on Offer Wall Edge
          </h3>
        </div>
        <p>
          We are excited to announce that Multi-Reward offers, that enable you
          to gain <strong>better engagement and retention rates</strong> from
          acquired users, are now available on our Offer Wall Edge!
        </p>
        <ul>
          <li>
            {" "}
            <p>
              Multi-Reward offers allow you to create a more engaging experience
              for users. As they progress with the required actions, they earn
              incremental rewards in the journey to reach the final objective.
            </p>
          </li>
          <li>
            {" "}
            <p>
              With our <strong>self-serve ACP Edge</strong> you can define the
              total value of the offer (i.e. the bid), number of events and how
              that value is split between the different events.
            </p>
          </li>
        </ul>

        <p style={{ fontStyle: "italic" }}>
          Visit our detailed guide on{" "}
          <Link href="https://developer.fyber.com/hc/en-us/articles/360019015258-Multi-Reward-Offers-Beta-">
            how to set up Multi-Reward offers
          </Link>{" "}
          or contact your dedicated Account Manager.
        </p>
      </div>
    </Modal>
  );
};

function Link({ href, children }) {
  return (
    <a
      style={{ color: colors.softBlack }}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
}

export default withStyles({}, { withTheme: true })(FullscreenInfo);
