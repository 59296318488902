import { colors } from "theme";
import { DEFAULT_MODAL_HEIGHT, DEFAULT_MODAL_WIDTH } from "./Modal";

const styles = () => ({
  backdrop: {
    position: "fixed",
    zIndex: 1300,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    overflow: "auto",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
  root: {
    position: "absolute",
    cursor: "default",
    top: (props) => props.top || "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: colors.white,
    borderRadius: 5,
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.04), 0px 2px 12px rgba(0, 0, 0, 0.12)",
    width: (props) => (props.width ? props.width : DEFAULT_MODAL_WIDTH),
    minHeight: (props) =>
      props.height === false ? null : props.height || DEFAULT_MODAL_HEIGHT,
    color: colors.softBlack,
  },
  content: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    borderTop: (props) =>
      props.contentTopBorder ? `1px solid ${colors.gray[100]}` : null,
  },
});

export default styles;
