import { colors } from "theme";
import { TOP_APP_BAR_HEIGHT } from "../../utils/constants";

const styles = (theme) => ({
  appBar: {
    color: colors.softBlack,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // there seems to be no other way to manipulate "gutter" of nested ToolBar (see MUI's AppBar documentation)
    "& > div": {
      paddingLeft: "0",
    },
  },
  navlink: {
    textDecoration: "none",
    outline: "none",
  },
  navlinkMenu: {
    textDecoration: "none",
    outline: "none",
  },
  grow: {
    flexGrow: 1,
  },
  toolbar: {
    backgroundColor: colors.white,
    minHeight: TOP_APP_BAR_HEIGHT,
  },
  toolbarFont: {
    fontWeight: 400,
  },
  dateRange: {
    width: 110,
  },
});

export default styles;
