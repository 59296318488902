import { Redirect, Route } from "react-router-dom";
import { useUserStore } from "store/user";
import { PAGE_PATHS } from "./routes";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useUserStore((state) => state.isAuthenticated);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          return <Component {...props} {...rest} />;
        }

        return (
          <Redirect
            to={{ pathname: PAGE_PATHS.login, state: { from: props.location } }}
          />
        );
      }}
    />
  );
};
