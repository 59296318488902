import Rollbar, { Configuration } from "rollbar";

const isEnabled =
  process.env.REACT_APP_ENVIRONMENT === "production" ||
  process.env.REACT_APP_ENVIRONMENT === "staging";

export const rollbarConfig: Configuration = {
  accessToken: process.env.REACT_APP_ROLLBAR_CLIENT_TOKEN,
  environment: process.env.REACT_APP_ENVIRONMENT,
  enabled: isEnabled,
  captureIp: "anonymize",
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
  },
};

const rollbar = new Rollbar(rollbarConfig);

export default rollbar;
