import { withStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import { colors } from "theme";

const DashboardLink = () => (
  <NavLink to={`/`} style={{ textDecoration: "none", color: colors.softBlack }}>
    Dashboard
  </NavLink>
);

export default withStyles(null, { withTheme: true })(DashboardLink);
