import axios from "axios";
import { DATA_PATHS } from "utils/dataPaths";
import rollbar from "utils/rollbar";
import { logoutUserStorage } from "./logoutUserStorage";

// left for documentation
// import { handleMockRequest, isMockError, handleMockResponse } from "./axios-mock";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    Authorization: localStorage.token,
  },
});

// @ts-expect-error
api.CancelToken = axios.CancelToken;
// @ts-expect-error
api.isCancel = axios.isCancel;

api.interceptors.request.use(
  (request) => {
    request.headers["Authorization"] = localStorage.token;

    if (request.method && request.method.toLowerCase() === "post") {
      request.headers["Content-Type"] = "application/json";
    }

    if (request.url.endsWith("graphql")) {
      request.withCredentials = false;
      return request;
    }

    if (
      request.url.startsWith("/users") &&
      process.env.REACT_APP_API_URL !== ""
    ) {
      // use defined backend endpoint for REST requests (login/logout/register...)
      return request;
    }

    return request;
  },
  (error) => {
    rollbar.error(error);
    return Promise.reject(error);
  },
);

// "error.message" below is a "non-enumerable" property and is removed during decomposition
// > "Non-Enumerables are properties that dont belong to the object but belong to the objects prototype chain.
// > In other words, they were inherited from whatever Object they were constructed from."
//
// "hasResponse" is added to indicate whether the error was submitted from server (true), or it had a
// different reason, e.g. server unreachable, not connected to network,... (false)
api.interceptors.response.use(
  (response) => {
    if (response.config.url.endsWith("graphql") && response.data) {
      const errorMessages = [];
      if (response.data.data) {
        // if there was an internal GraphQL error we handle it like an Axios error
        // so that the error message can be shown properly
        for (const value of Object.values<any>(response.data.data)) {
          if (
            value &&
            value.errors &&
            Array.isArray(value.errors) &&
            value.errors.length > 0
          ) {
            value.errors.map((error) =>
              errorMessages.push(error.message ? error.message : String(error)),
            );
          }
        }
      } else if (response.data.errors) {
        response.data.errors.map((error) =>
          errorMessages.push(error.message ? error.message : String(error)),
        );
      }
      if (errorMessages.length > 0) {
        const error = {
          displayMessage: errorMessages,
          hasResponse: true,
        };
        rollbar.error(error);
        return Promise.reject(error);
      }
    }

    return response; // simply return response
  },
  (error) => {
    // if (isMockError(error)) {
    //   return handleMockResponse(error);
    // }

    if (error.response) {
      if (
        error.response.status &&
        String(error.response.status).startsWith("401")
      ) {
        // unauthorised

        logoutUserStorage();

        if (
          !error.config.url.endsWith(DATA_PATHS.LOGIN_REST_POST) &&
          window.location.pathname !== "/login"
        ) {
          window.location.href = "/login";
          return;
        }
      }

      if (
        error.response.data &&
        (error.response.data.message ||
          error.response.data.error ||
          error.response.data.errors)
      ) {
        const { message, errors, error: errorMsg } = error.response.data;
        let displayMessage =
          message || errors || (errorMsg.message ?? errorMsg);

        if (errors?.email[0] === "not found")
          displayMessage = "Email not found";

        error = {
          ...error,
          hasResponse: true,
          displayMessage,
        };
      }
    } else {
      error = { ...error, displayMessage: error.message, hasResponse: false };
    }

    const rollbarError = scrubSensitiveData(error);
    rollbar.error(rollbarError);
    return Promise.reject(error);
  },
);

function scrubSensitiveData(error) {
  error.config.data = JSON.parse(error.config.data);
  if (error.config.data.user.password) delete error.config.data.user.password;
  error.config.data = JSON.stringify(error.config.data);
  return error;
}

export default api;
