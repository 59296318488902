import { withStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import { colors } from "theme";
import HtmlTooltip from "../UI/HtmlTooltip/HtmlTooltip";

const styles = () => ({
  link: {
    height: 40,
    paddingLeft: 8,
    paddingRight: 8,
    textDecoration: "none",
    color: colors.softBlack,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: colors.gray[100],
    },
  },
  div: (props) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: props.addPadding ? 8 : null,
    paddingRight: props.addPadding ? 8 : null,
  }),
});

const BreadcrumbItem = (props) => {
  const { tootltipText, children, linkTo, classes } = props;

  const content = linkTo ? (
    <NavLink to={linkTo} className={classes.link}>
      {children}
    </NavLink>
  ) : (
    <div className={classes.div}>{children}</div>
  );

  return tootltipText ? (
    <HtmlTooltip
      title={tootltipText}
      placement="bottom"
      fontSize={12}
      noShadow
      inverse
    >
      {content}
    </HtmlTooltip>
  ) : (
    <>{content}</>
  );
};

export default withStyles(styles, { withTheme: true })(BreadcrumbItem);
