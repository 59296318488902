import { colors } from "theme";
import IconHelper from "./IconHelper";

const CloseIcon = (props) => {
  return (
    <IconHelper {...props} defaultWidth={14} defaultHeight={14}>
      <g transform="translate(-5,-5)">
        <polygon
          id="Shape"
          points="6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41 17.59 5 12 10.59"
          fill={colors.gray[700]}
        />
      </g>
    </IconHelper>
  );
};

export default CloseIcon;
