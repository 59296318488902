import { format } from "date-fns";
import { EmptyDate, Timestamp } from "types/misc";

export const DateUtils = {
  isDateValid: (date: unknown): boolean =>
    date instanceof Date && !Number.isNaN(date.getTime()),

  removeSecondsGetTime: function (input: unknown): number {
    if (input === null) return null;
    if (!this.isDateValid(input)) return NaN;
    const date = new Date(input as Date);
    date.setSeconds(0, 0);
    return date.getTime();
  },

  // no ES6 syntax because of scope (this.getFakedUTCDateFromDate())
  getUTCTodayWithoutTime: function (): Date {
    const today = this.getFakedUTCDateFromDate();
    today.setHours(0, 0, 0, 0);
    return today;
  },

  getFakedUTCDateFromSeconds: function (seconds?: number): Date {
    return this.getFakedUTCDateFromDate(
      seconds ? new Date(seconds * 1000) : new Date(),
    );
  },

  getFakedUTCDateFromDate: (date?: Date): Date => {
    const dateToHandle = date ? date : new Date();
    return new Date(
      dateToHandle.getTime() + dateToHandle.getTimezoneOffset() * 60000,
    );
  },

  getFakedUTCMillisecondsFromMilliseconds: (milliseconds?: number): number => {
    const millisecondsToHandle = milliseconds
      ? milliseconds
      : new Date().getTime();
    return new Date(
      millisecondsToHandle +
        new Date(millisecondsToHandle).getTimezoneOffset() * 60000,
    ).getTime();
  },

  getMillisecondsFromFakedUTCMilliseconds: (milliseconds?: number): number => {
    const millisecondsToHandle = milliseconds
      ? milliseconds
      : new Date().getTime();
    return new Date(
      millisecondsToHandle -
        new Date(millisecondsToHandle).getTimezoneOffset() * 60000,
    ).getTime();
  },

  getFormattedTimeFromMilliseconds: (milliseconds: number): string => {
    const minutes = Math.floor(milliseconds / 60000);
    const seconds = Math.floor((milliseconds % 60000) / 1000);

    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0",
    )}`;
  },
};

export const getCreatedAt = (createdAt: Timestamp): string => {
  const fakeDate = DateUtils.getFakedUTCDateFromSeconds(createdAt);
  const formatted = format(fakeDate, "yyyy-MM-dd HH:mm");
  return `${formatted} (UTC)`;
};

/**
 * Checks if a date is in future.
 *
 * @param dateMs number or string that should represent a date in ms
 * @returns false if argument is a number > 0 that represents date in ms which is in the past
 */
export const isDateInFutureOrEmpty = (
  dateMs?: Timestamp | EmptyDate | string,
): boolean => {
  // backend returns dates as string though it's defined as Int/BigInt
  const testDate = parseInt(dateMs ? `${dateMs}` : null);
  if (!testDate || testDate === 0) {
    return true;
  }
  return testDate > Date.now();
};
