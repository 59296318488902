import { colors } from "theme";

const styles = () => ({
  tooltip: (props) => ({
    backgroundColor:
      props.backgroundColor ||
      (props.inverse ? colors.softBlack : colors.white),
    borderRadius: props.borderRadius || 5,
    color: props.color || (props.inverse ? colors.white : colors.softBlack),
    maxWidth: props.maxWidth || 600,
    padding: props.padding || 12,
    fontSize: props.fontSize || 12,
    fontWeight: props.fontWeight || 400,
    "& > *": {
      display: "inline-block",
    },
    left: props.adjustXPosition ? props.adjustXPosition : null,
    top: props.adjustYPosition ? props.adjustYPosition : null,
    boxShadow: props.noShadow
      ? null
      : "0px 2px 2px rgba(0, 0, 0, 0.04), 0px 2px 12px rgba(0, 0, 0, 0.12)",
  }),
  arrow: (props) => ({
    backgroundColor: "transparent",
    color:
      props.backgroundColor ||
      (props.inverse ? colors.softBlack : colors.white),
  }),
});

export default styles;
