import { gql } from "@apollo/client";

const DELETE_OFFER_CAMPAIGN = gql`
  mutation offerCampaignDestroy($id: ID!) {
    offerCampaignDestroy(input: { id: $id }) {
      errors
    }
  }
`;

export default DELETE_OFFER_CAMPAIGN;
