import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import styles from "./HtmlTooltipStyles";

// TODO it could be required to not style direct children as "display: inline-block"
// TODO in this case we need a new property which would be used in HtmlTooltipStyles.js then

const HtmlTooltip = ({ adjustXPosition, adjustYPosition, ...props }) => {
  const {
    classes,
    "data-cy": dataCy,
    maxWidth,
    noShadow,
    inverse,
    children,
    theme,
    ...rest
  } = props;

  return (
    <Tooltip
      data-cy={dataCy}
      {...rest}
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
      }}
      PopperProps={{
        "data-cy": `${dataCy}_popper`,
      }}
      arrow
    >
      {children}
    </Tooltip>
  );
};

HtmlTooltip.propTypes = {
  placement: PropTypes.string,
  noShadow: PropTypes.bool,
  inverse: PropTypes.bool, // swaps color and bg color (color and backgroundColor will overwrite this)
  title: PropTypes.any,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  borderRadius: PropTypes.number,
  maxWidth: PropTypes.number,
  fontSize: PropTypes.number,
  fontWeight: PropTypes.number,
};

export default withStyles(styles, { withTheme: true })(HtmlTooltip);
