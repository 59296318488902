import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider as Provider,
  from,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { createConsumer } from "@rails/actioncable";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import ActionCableLink from "graphql-ruby-client/subscriptions/ActionCableLink";
import { PAGE_PATHS } from "routes/routes";
import { useNotificationStore } from "store/notification";
import { logoutUserStorage } from "utils/logoutUserStorage";
import rollbar from "utils/rollbar";

const cable = createConsumer(process.env.REACT_APP_API_SUBSCRIPTION_URL);

// export to allow cache manipulations
export const cache = new InMemoryCache({
  addTypename: false,
});

const hasSubscriptionOperation = ({ query: { definitions } }) => {
  return definitions.some(
    ({ kind, operation }) =>
      kind === "OperationDefinition" && operation === "subscription",
  );
};

// also acts as HttpLink
const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL + "/graphql",
});

const authLink = setContext((request) => {
  const headers = {
    authorization: localStorage.token ?? "",
  };

  if (window["Cypress"]) {
    // e2e tests are running - add header for intercepting
    headers["operationName"] = request.operationName;
  }

  return { headers };
});

const errorLink = onError(({ graphQLErrors, response, networkError }) => {
  const addErrorMessage = useNotificationStore.getState().addErrorMessage;

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      const error = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
      rollbar.error(error);
    });
  }

  if (!networkError) return;

  rollbar.error(`[Network error]: ${networkError}`);

  // 500's will be available via query's/mutation's error object
  // affected queries/mutations will need "onError" option, otherwise app will crash
  // @ts-expect-error types are missing
  if (networkError.statusCode >= 500) return;

  // @ts-expect-error types are missing
  if (networkError.statusCode === 401) {
    if (response) {
      response.errors = null; // redirect to login w/o showing "network error"
    }
    logoutUserStorage();
    window.location.href = PAGE_PATHS.login;
  }
  // @ts-expect-error types are missing
  else if (networkError.statusCode === 413) {
    addErrorMessage("Upload size is too large");
  }
  // Show a general message in case of any unknown errors
  else {
    addErrorMessage("An error occurred");
  }
});

const apiLink = from([errorLink, authLink.concat(uploadLink)]);

const splitLink = split(
  hasSubscriptionOperation,
  new ActionCableLink({ cable }),
  apiLink,
);

const apolloClient = new ApolloClient({
  link: splitLink,
  connectToDevTools: true,
  cache,
});

const ApolloProvider = ({ children }) => (
  <Provider client={apolloClient}>{children}</Provider>
);
export default ApolloProvider;
