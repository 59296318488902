import { gql } from "@apollo/client";

const GET_ALL_PRODUCTS = gql`
  query advertiserProducts(
    $advertiserId: ID!
    $startDate: BigInt
    $endDate: BigInt
  ) {
    advertiserProducts(
      advertiserId: $advertiserId
      startDate: $startDate
      endDate: $endDate
    ) {
      activeOfferCampaignsCount
      adContent
      advertiserId
      appCategoryGenre
      bundleId
      cmsId
      druidResults
      enabled
      iconUrl
      id
      name
      platform
      samsungStore
      secondaryCategories
      secureAppid
      url
    }
  }
`;

export default GET_ALL_PRODUCTS;
