import { withStyles } from "@material-ui/core/styles";
import { colors } from "theme";

const styles = () => ({
  root: {
    width: 1,
    height: 24,
    borderLeft: `1px solid ${colors.gray[200]}`,
    marginLeft: 8,
    marginRight: 8,
  },
});

const BreadcrumbItemDivider = ({ classes }) => {
  return <div className={classes.root}></div>;
};

export default withStyles(styles, { withTheme: true })(BreadcrumbItemDivider);
